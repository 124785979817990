import { Coordinates } from '../models/Coordinates';

export const plan = require('../../assets/images/store-plan.png');

export const shopperInquiriesMock = [
  { index: 1, id: '#BL4102231', date: new Date(2020, 4, 2), sum: 160 },
  { index: 2, id: '#BLdfe4132', date: new Date(2020, 4, 3), sum: 82.99 },
  { index: 3, id: '#BLegy4202', date: new Date(2020, 4, 4), sum: 42.09 },
  { index: 4, id: '#BLegy4103', date: new Date(2020, 4, 4), sum: 42.09 },
];

export const customersInStoreMock = [
  {
    nickName: 'silbia80',
    avatar: require('../../assets/images/avatar.png'),
    dateTime: new Date(2020, 4, 4, 12, 32),
  },
  {
    nickName: 'skjientksoie',
    avatar: require('../../assets/images/avatar-1.png'),
    dateTime: new Date(2020, 4, 4, 12, 30),
  },
  { nickName: 'oceanveiweoi', dateTime: new Date(2020, 4, 4, 12, 19) },
  {
    nickName: 'Kimmitorue',
    avatar: require('../../assets/images/avatar-2.png'),
    dateTime: new Date(2020, 4, 4, 11, 58),
  },
  {
    nickName: 'Kimmitorue',
    avatar: require('../../assets/images/avatar-3.png'),
    dateTime: new Date(2020, 4, 4, 12, 14),
  },
  {
    nickName: 'Trueslove',
    avatar: require('../../assets/images/avatar-4.png'),
    dateTime: new Date(2020, 4, 4, 11, 50),
  },
];

export const grossSalesMock = {
  labels: [],
  datasets: [
    {
      data: [20, 45, 28, 80, 99, 43, 40, 45, 28, 84, 99, 33],
      color: () => `rgba(100, 73, 197)`,
      strokeWidth: 2,
    },
  ],
};

export const storeStaticMock = {
  saleData: {
    labels: [],
    datasets: [
      {
        data: [20, 45, 28, 80, 99, 43],
        color: () => `rgba(100, 73, 197)`,
        strokeWidth: 9,
      },
    ],
  },

  visitData: {
    labels: [],
    datasets: [
      {
        data: [65, 55, 40, 55, 60, 39, 31, 28],
        color: () => `rgba(254,134,88)`,
        strokeWidth: 9,
      },
    ],
  },

  totalUnitsData: {
    labels: [],
    datasets: [
      {
        data: [1, 3, 2, 4, 4, 3],
        color: () => `rgba(100, 73, 197)`,
        strokeWidth: 9,
      },
    ],
  },
};

export const salesStaticMockData = {
  labels: ['7am', '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm'],
  datasets: [
    {
      data: [
        13243.82, 14140.17, 13330.27, 15330.87, 16040.87, 19010.27, 20340.87, 20740.87, 14340.87, 13340.87, 14340.87,
        12340.87, 10000.21,
      ],
      color: () => `rgba(100, 73, 197)`,
      strokeWidth: 4,
      propsForDots: {
        r: '6',
      },
    },
    {
      data: [
        12243.82, 11140.17, 14330.27, 10330.87, 14040.87, 16010.27, 19340.87, 21740.87, 15340.87, 12340.87, 14540.87,
        11340.87, 9000.21,
      ],
      color: () => `rgba(140, 23, 117, 0.2)`,
      strokeWidth: 2,
      withShadow: false,
      propsForDots: {
        r: '2',
      },
    },
  ],
};

export const unitPositionMap = new Map();
unitPositionMap.set('5e72c81af18192b94796e7bd', new Coordinates(450, 140));
unitPositionMap.set('5f6b5f7af192be79df97c6bb', new Coordinates(270, 100));
unitPositionMap.set('5f6b5f88f192be79df97c6bc', new Coordinates(130, 240));
unitPositionMap.set('60de2518123bff71b0bc5b4c', new Coordinates(130, 440));
