import React, {useState, useEffect} from 'react';
import { StyleSheet, ScrollView, View, ActivityIndicator} from 'react-native';
import { Unit } from '../models/Unit';
import SalesTable from '../components/sales/SalesTable';
import {MostSoldProducts} from '../components/sales/MostSoldProducts';
import { TableCellTypeEnum as TCTE } from '../enums/TableCellType.enum';
import { ICellSize } from '../interfaces/ICellSize';
import { colorPalette } from '../styles/Theme';
import { useAppState } from '../contexts/AuthContext';
import { ProductService } from '../services/ProductService';
import { StoreService } from '../services/StoreService';
import { Bin } from '../models/Bin';

import SaleStatic from '../components/sales/SaleStatic';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    // backgroundColor: colorPalette.basic.white,
    borderRadius: 24,
    padding: 32,
    paddingVertical: 12,
  },
  tableContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: colorPalette.basic.white,
    borderRadius: 24,
    padding: 32,
    paddingVertical: 12,
  },
});

const SalesScreen: React.FC = () => {
  const { token, storeId } = useAppState();
  const [productMap, setProductMap] = useState(new Map());
  const [units, setUnits] = useState<Array<Unit>>([]);
  const [tableData, setTableData] = useState<Array<Array<any>>>([]);

  const labels = ['Picture', 'Name', 'Brand', 'Type', 'Unit sold', 'Sale', 'Total sold'];
  const customSizeMap = new Map<number, ICellSize>([[0, { fixed: 96 }]]);
  const cellTypes = [
    TCTE.Image,
    TCTE.Text,
    TCTE.Text,
    TCTE.Text,
    TCTE.Number,
    TCTE.Text,
    TCTE.Currency,
  ];

  useEffect(() => {
    if (!token || !storeId) return;
    ProductService.getAllProducts().then(data => {
      const productMap = new Map(data.map(p => [p.id, p]));
      setProductMap(productMap);
    });
    StoreService.getStoreUnits(storeId).then(setUnits);
  }, [token, storeId]);

  useEffect(() => {
    if (units.length) {
      const tableData: Array<Array<any>> = [];
      units.forEach((unit: Unit) => {
        const unitName = unit.name;
        unit.binList.forEach((bin: Bin) => {
          tableData.push([
            require('../../assets/images/drinks/coke.png'),
            'Diet Coke',
            'Coka Cola',
            'Drink',
            43,
            '68%',
            3565.35,
          ]);
        });
      });
      setTableData(tableData);
    }
  }, [productMap, units]);

  return (
    <View style={styles.container}>
      <ScrollView style={{ flex: 1, width: '100%' }}>
        <View style={{marginBottom: 32, flexDirection: 'row'}}>
          <SaleStatic />
          <MostSoldProducts />
        </View>
        <View style={styles.tableContainer}>
          {units.length && productMap.size && tableData.length ? (
            <SalesTable dataset={tableData} labels={labels} customSizeMap={customSizeMap} cellTypes={cellTypes} />
          ) : (
            <ActivityIndicator size={26} color={colorPalette.primary} />
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default SalesScreen;
