import React, { useState } from 'react';
import { StyleSheet, Text, View, Image, ImageBackground } from 'react-native';
import SalesChart from '../SalesChart';
import ChartCardMini from '../ChartCardMini';
import { latoFontStyle } from '../../styles/Shared';
import { colorPalette } from '../../styles/Theme';
import moment from 'moment';
import { TIME_FORMAT } from '../../configs/Constants';
import DatePicker from '../elements/DatePicker';
import { salesStaticMockData, storeStaticMock } from '../../mocks/MockData';
import {LineChart} from 'react-native-chart-kit'
import { abbreviateNumber } from 'js-abbreviation-number';

interface ChartObject {
  data: Array<number>,
  color: () => string;
  strokeWidth: number;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: colorPalette.basic.white,
    borderRadius: 24,
    padding: 32,
    paddingBottom: 14,
    // maxHeight: 360,
  },
  headerContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    flex: 1,
    flexWrap: 'wrap',
  },
  chartContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  divider: {
    width: 0.5,
    height: 57,
    backgroundColor: colorPalette.other.divider,
    alignSelf: 'center',
  }
});

export const SaleStatic: React.FC = () => {
  const [chartWidth, setChartWidth] = useState(0);
  const lastUpdatedTime = new Date();

  const { saleData, totalUnitsData } = storeStaticMock;

  const datasets: Array<ChartObject> = [];

  datasets.push({
    data: [
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
    ],
    color: () => colorPalette.primary,
    strokeWidth: 4,
  });
  datasets.push({
    data: [
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
      Math.round(Math.random() * 4000 + 1000),
    ],
    color: () => `${colorPalette.basic.coralRed}30`,
    strokeWidth: 4,
  });
  datasets.push({
    data: [1000, 5000],
    color: () => colorPalette.basic.white,
    strokeWidth: 0.1,
  });

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View style={{ flex: 1 }} onLayout={event => setChartWidth(event.nativeEvent.layout.width)}>
          <View style={styles.headerContainer}>
            <View style={styles.titleContainer}>
              <Text style={[latoFontStyle.regular, { fontSize: 18 }]}>Customer Visit Statistics&nbsp;&nbsp;</Text>
              <Text style={[latoFontStyle.regular, { fontSize: 12, color: colorPalette.basic.dustyGray }]}>
                Last Updated : {moment(lastUpdatedTime).format(TIME_FORMAT)}
              </Text>
            </View>
            <View style={styles.chartContainer}>
              <Image source={require('../../../assets/images/search.png')} style={{width: 16, height: 16, marginRight: 13}} />
              <Image source={require('../../../assets/images/download.png')} style={{width: 18, height: 18, marginRight: 18}} />
              <View style={{ flexDirection: 'row', paddingVertical: 8, paddingHorizontal: 10, marginRight: 32, borderWidth: 1, borderRadius: 4, borderColor: colorPalette.basic.whisper, alignItems: 'center'}}>
                <Text style={{color: colorPalette.basic.lightBlack, fontSize: 14}}>Today</Text>
                <Image source={require('../../../assets/images/calendar.png')} style={{ width: 14, height: 14, marginLeft: 10 }} />
              </View>
            </View>
          </View>
          <View style={{flexDirection: 'row'}}>
            <View style={{marginTop: 16}}>
              <Text style={{fontWeight: '700', letterSpacing: 0.2, color: colorPalette.basic.gunPowder, marginBottom: 11.8, marginLeft: 17}}>Customer Heat Map</Text>
              <Image source={require('../../../assets/images/scale-customer-visit.png')} style={{width: 104, height: 11, marginLeft: 243}}/>
              <ImageBackground source={require('../../../assets/images/heat-map.png')} style={{width: 319, height: 333, marginLeft: 47}}>
                <Text style={{position: 'absolute', top: 9, right: 116, color: colorPalette.basic.dustyGray, fontSize: 12}}>5</Text>
                <Text style={{position: 'absolute', top: 9, right: 19, color: colorPalette.basic.dustyGray, fontSize: 12}}>100</Text>
              </ImageBackground>
            </View>
            <View style={{marginLeft: 74}}>
              <View style={{flexDirection: 'row', marginLeft: 35, marginBottom: 40}}>
                <View style={{flexDirection: 'row'}}>
                  <View style={{borderWidth: 1.6, borderColor: colorPalette.basic.cyberPurple, borderRadius: 5, width: 16, height: 16}}></View>
                  <Text style={{marginLeft: 6}}># of Customer</Text>
                </View>
                <View style={{marginLeft: 12, flexDirection: 'row'}}>
                  <View style={{borderWidth: 1.6, borderColor: colorPalette.basic.salmon, borderRadius: 5, width: 16, height: 16}}></View>
                  <Text style={{marginLeft: 6}}>Recurrent Customer</Text>
                </View>
              </View>
              <LineChart
                data={{
                  labels: ['7', '8', '9', '10', '11', '12pm', '1', '2', '3', '4', '5'],
                  datasets: datasets
                }}
                withVerticalLines={false}
                width={630}
                height={220}
                formatYLabel={value => (Math.round(Number(value)/1000)*1000).toString()}
                getDotColor={() => {
                  return '#00000000';
                }}
                chartConfig={{
                  backgroundGradientFromOpacity: 0,
                  backgroundGradientToOpacity: 0,
                  decimalPlaces: 2, // optional, defaults to 2dp
                  color: () => `${colorPalette.basic.greyFourty}30`,
                  labelColor: () => colorPalette.basic.greyFourty,
                  useShadowColorFromDataset: true,
                  propsForBackgroundLines: {
                    strokeDasharray: null,
                  },
                  propsForHorizontalLabels: {
                    fontSize: 12,
                    fontFamily: latoFontStyle.regular.fontFamily,
                  },
                  propsForVerticalLabels: {
                    fontSize: 12,
                    fontFamily: latoFontStyle.regular.fontFamily,
                  }
                }}
                bezier
                segments={3}
              />
              <View>
                <View style={{width: 630, flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between', marginTop: 28}}>
                  <ChartCardMini
                    title="Average return"
                    unitsSymbol="$"
                    value={40000}
                    change={3.0}
                    chartData={saleData}
                    onPress={() => {}}
                  />

                  <View style={styles.divider} />

                  <ChartCardMini
                    title="Average number of items"
                    value={345}
                    change={-4.4}
                    chartData={totalUnitsData}
                    onPress={() => {}}
                  />
                </View>
                <View style={{ width: 630, flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between', marginTop: 28}}>
                  <ChartCardMini
                    title="Average spent value"
                    unitsSymbol="$"
                    value={40000}
                    change={3.0}
                    chartData={saleData}
                    onPress={() => {}}
                  />

                  <View style={styles.divider} />

                  <ChartCardMini
                    title="Average time per customer"
                    value={345}
                    change={-4.4}
                    chartData={totalUnitsData}
                    onPress={() => {}}
                  />
                </View>
              </View>
            </View>
          </View>
          <Text style={{textAlign: 'right', color: colorPalette.basic.coalGray, fontSize: 10}}>From yesterday</Text>
        </View>
      </View>
    </View>
  );
};

export default SaleStatic;
