import apiClient from '../utils/ApiClient';
import { AxiosResponse } from 'axios';
import { plainToClass } from 'class-transformer';
import { User } from '../models/User';
import { Unit } from '../models/Unit';
import { ProductService } from './ProductService';

export class StoreService {
  public static async getUsersInStore(storeId: string): Promise<Array<User>> {
    return apiClient.get(`stores/${storeId}`).then((response: AxiosResponse) => {
      return plainToClass(User, response.data.data.store.currentUserStatusList) || [];
    });
  }

  public static async getUnitIdsInStore(storeId: string): Promise<Array<string>> {
    return apiClient.get(`stores/${storeId}`).then((response: AxiosResponse) => {
      return response.data.data.store.unitIdList;
    });
  }

  public static async getStoreUnits(storeId: string): Promise<Array<Unit>> {
    const unitIdsInStore = await StoreService.getUnitIdsInStore(storeId);
    const unitsRequests = unitIdsInStore.map(unitId =>
      apiClient.get(`/units/${unitId}`).then((response: AxiosResponse) => response.data.data.unit),
    );
    return plainToClass(Unit, await Promise.all(unitsRequests));
  }

  public static async getStoreUnitsWithProductInfo(storeId: string): Promise<Array<Unit>> {
    const units = await StoreService.getStoreUnits(storeId);
    for await (const unit of units) {
      for await (const bin of unit.binList) {
        bin.product = await ProductService.getProductById(bin.productId);
      }
    }
    return units;
  }
}
