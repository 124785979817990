import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, ViewProps, TouchableOpacity, Platform } from 'react-native';
import { latoFontStyle } from '../../styles/Shared';
import { colorPalette } from '../../styles/Theme';
import { ProblemTypeEnum } from '../../enums/ProblemType.enum';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    ...latoFontStyle.black,
    fontWeight: '900',
    fontSize: 14,
    marginRight: 32,
    paddingHorizontal: 16,
    paddingVertical: 4,
    color: colorPalette.basic.boulder,
  },
  highlighted: {
    backgroundColor: colorPalette.primary,
    borderRadius: Platform.OS === 'ios' ? 12 : 16,
    color: colorPalette.basic.white,
    overflow: 'hidden',
  },
  borderRadius: {
    borderRadius: 16,
  },
});

interface StoreNotificationsProps extends ViewProps {
  countLowStock?: number;
  countMisplace?: number;
  countSystemError?: number;
  onChange?: (type: ProblemTypeEnum | 'All') => void;
}

const StoreNotifications: React.FC<StoreNotificationsProps> = ({
  style,
  countLowStock = 0,
  countMisplace = 0,
  countSystemError = 0,
  onChange,
}) => {
  const [allCount, setAllCount] = useState(countLowStock + countMisplace + countSystemError);
  const [selected, setSelected] = useState<ProblemTypeEnum | 'All' | undefined>(allCount > 0 ? 'All' : undefined);

  useEffect(() => {
    if (onChange && selected) onChange(selected);
  }, [onChange, selected]);

  useEffect(() => {
    const allCount = countLowStock + countMisplace + countSystemError;
    setAllCount(allCount);
    setSelected(allCount > 0 ? 'All' : undefined);
  }, [countLowStock, countMisplace, countSystemError]);

  return (
    <View style={[style, styles.container]}>
      {Boolean(allCount) && (
        <TouchableOpacity onPress={() => setSelected('All')}>
          <Text style={[styles.message, selected === 'All' ? styles.highlighted : {}]}>All</Text>
        </TouchableOpacity>
      )}
      {Boolean(countLowStock) && (
        <TouchableOpacity onPress={() => setSelected(ProblemTypeEnum.LowStock)}>
          <Text
            style={[
              styles.message,
              selected === ProblemTypeEnum.LowStock ? styles.highlighted : {},
              { backgroundColor: selected === ProblemTypeEnum.LowStock ? colorPalette.basic.salmon : 'transparent' },
            ]}
          >
            {countLowStock} Low Stock
          </Text>
        </TouchableOpacity>
      )}
      {Boolean(countMisplace) && (
        <TouchableOpacity onPress={() => setSelected(ProblemTypeEnum.Misplace)}>
          <Text
            style={[
              styles.message,
              selected === ProblemTypeEnum.Misplace ? styles.highlighted : {},
              {
                backgroundColor: selected === ProblemTypeEnum.LowStock ? colorPalette.basic.curiousBlue : 'transparent',
              },
            ]}
          >
            {countMisplace} Misplace
          </Text>
        </TouchableOpacity>
      )}
      {Boolean(countSystemError) && (
        <TouchableOpacity onPress={() => setSelected(ProblemTypeEnum.System)}>
          <Text
            style={[
              styles.message,
              selected === ProblemTypeEnum.System ? styles.highlighted : {},
              {
                backgroundColor: selected === ProblemTypeEnum.System ? colorPalette.basic.heliotrope : 'transparent',
              },
            ]}
          >
            {countSystemError} System error
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default React.memo(StoreNotifications);
