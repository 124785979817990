import { Expose } from 'class-transformer';
import { BIN_LOW_STOCK_COUNT, VALID_STATUS } from '../configs/Constants';
import { ProblemTypeEnum } from '../enums/ProblemType.enum';
import { Product } from './Product';

export class Bin {
  @Expose()
  public binNum: number;

  @Expose()
  public productCount: number;

  public product?: Product;

  @Expose()
  public productId: string;

  @Expose()
  public productName: string;

  @Expose()
  public productPrice: number;

  @Expose()
  public productWeight: number;

  @Expose()
  public status: string;

  constructor(
    binNum: number,
    productCount: number,
    productId: string,
    productName: string,
    productPrice: number,
    productWeight: number,
    status: string,
  ) {
    this.binNum = binNum;
    this.productCount = productCount;
    this.productId = productId;
    this.productName = productName;
    this.productPrice = productPrice;
    this.productWeight = productWeight;
    this.status = status;
  }

  public hasHardwareProblem(): boolean {
    return this.status !== VALID_STATUS;
  }

  public hasLowStockProblem(): boolean {
    return this.productCount <= BIN_LOW_STOCK_COUNT;
  }

  public hasProblem(): boolean {
    const hasHardwareProblem = this.hasHardwareProblem();
    const hasLowStockProblem = this.hasLowStockProblem();
    return hasHardwareProblem || hasLowStockProblem;
  }

  get primaryProblem(): ProblemTypeEnum | undefined {
    const hasHardwareProblem = this.hasHardwareProblem();
    const hasLowStockProblem = this.hasLowStockProblem();
    if (hasHardwareProblem) {
      return ProblemTypeEnum.System;
    } else if (hasLowStockProblem) {
      return ProblemTypeEnum.LowStock;
    }
    return undefined;
  }
}
