import { Expose, Type } from 'class-transformer';
import { IsArray } from 'class-validator';
import { ImageSourcePropType } from 'react-native';

export class Product {
  @Expose()
  public id: string;

  @Expose()
  public name: string;

  @Expose()
  @Type(() => String)
  @IsArray()
  public imageUrlList: Array<string>;

  constructor(id: string, name: string, imageUrlList: Array<string>) {
    this.id = id;
    this.name = name;
    this.imageUrlList = imageUrlList;
  }

  get imageUrl(): ImageSourcePropType {
    return this.imageUrlList?.length ? { uri: this.imageUrlList[0] } : require('../../assets/images/product-image.png');
  }
}
