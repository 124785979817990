import React, { useState } from 'react';
import { IconButton, Menu } from 'react-native-paper';
import { colorPalette } from '../../styles/Theme';

interface TableFilterMenuProps {
  values?: Array<string | number>;
  changeHandler?: (value: string | number) => void;
}

const TableFilterMenu: React.FC<TableFilterMenuProps> = ({ values, changeHandler }) => {
  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const [isActive, setActive] = useState(false);

  return (
    <React.Fragment>
      {/* @ts-ignore*/}
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        style={{}}
        anchor={
          /* @ts-ignore*/
          <IconButton
            size={16}
            color={isActive ? colorPalette.primary : colorPalette.basic.black}
            icon="filter"
            onPress={openMenu}
          />
        }
      >
        {values &&
          values.map(value => (
            <Menu.Item
              key={value}
              onPress={() => {
                if (typeof changeHandler === 'function') {
                  changeHandler(value);
                  setActive(value !== 'All');
                }
                closeMenu();
              }}
              title={value}
            />
          ))}
      </Menu>
    </React.Fragment>
  );
};

export default React.memo(TableFilterMenu);
