import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native';

import { screenStyles } from '../styles/Shared';
import ProductsTable from '../components/inventory/ProductsTable';
import { colorPalette } from '../styles/Theme';
import { StoreService } from '../services/StoreService';
import { useAppState } from '../contexts/AuthContext';
import { ProductService } from '../services/ProductService';
import { Unit } from '../models/Unit';
import { ICellSize } from '../interfaces/ICellSize';
import { TableCellTypeEnum as TCTE } from '../enums/TableCellType.enum';
import { Bin } from '../models/Bin';
import { plan } from '../mocks/MockData';
import StoreMonitor from '../components/StoreMonitor';
import InventoryStatistics from '../components/inventory/InventoryStatistics';
import InventoryStatic from '../components/InventoryStatic';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: colorPalette.basic.white,
    borderRadius: 24,
    padding: 32,
    paddingVertical: 12,
    marginTop: 32,
  },
});

const InventoryScreen: React.FC = () => {
  const { token, storeId } = useAppState();
  const [productMap, setProductMap] = useState(new Map());
  const [units, setUnits] = useState<Array<Unit>>([]);
  const [tableData, setTableData] = useState<Array<Array<any>>>([]);

  const labels = ['Picture', 'Name', 'Brand', 'Type', 'Items on Shelve', 'Location', 'Price', 'Total store Inventory'];
  const customSizeMap = new Map<number, ICellSize>([[0, { fixed: 96 }]]);
  const cellTypes = [
    TCTE.ProductImage,
    TCTE.Text,
    TCTE.Text,
    TCTE.Text,
    TCTE.LowStock,
    TCTE.Text,
    TCTE.Currency,
    TCTE.Number,
    TCTE.Hidden,
  ];

  useEffect(() => {
    if (!token || !storeId) return;
    ProductService.getAllProducts().then(data => {
      const productMap = new Map(data.map(p => [p.id, p]));
      setProductMap(productMap);
    });
    StoreService.getStoreUnits(storeId).then(setUnits);
  }, [token, storeId]);

  useEffect(() => {
    if (units.length) {
      const tableData: Array<Array<any>> = [];
      units.forEach((unit: Unit) => {
        const unitName = unit.name;
        unit.binList.forEach((bin: Bin) => {
          tableData.push([
            bin.productId,
            bin.productName,
            'Coka Cola',
            'Drink',
            bin.productCount,
            `${unitName} / Bin ${bin.binNum}`,
            bin.productPrice,
            100,
            unitName,
          ]);
        });
      });
      setTableData(tableData);
    }
  }, [productMap, units]);

  return (
    <View style={[screenStyles.container, { margin: 32 }]}>
      <ScrollView style={{ flex: 1, width: '100%' }}>
        <InventoryStatic />
        <View style={styles.container}>
          {units.length && productMap.size && tableData.length ? (
            <ProductsTable dataset={tableData} labels={labels} customSizeMap={customSizeMap} cellTypes={cellTypes} />
          ) : (
            <ActivityIndicator size={26} color={colorPalette.primary} />
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default InventoryScreen;
