import React, { useState } from 'react';
import { LayoutRectangle, Image, StyleSheet, Text, TouchableOpacity, View, ViewProps } from 'react-native';

import moment from 'moment';

import { colorPalette } from '../styles/Theme';
import DatePicker from './elements/DatePicker';
import { latoFontStyle } from '../styles/Shared';
import ChartCardMini from './ChartCardMini';
import { storeStaticMock } from '../mocks/MockData';
import { TIME_FORMAT } from '../configs/Constants';
import {PieChart} from 'react-native-chart-kit';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: colorPalette.basic.white,
    borderRadius: 24,
    padding: 32,
    paddingBottom: 16,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    flex: 1,
    flexWrap: 'wrap',
  },
  cards: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: 16,
    marginHorizontal: -8,
  },
  divider: {
    width: 0.5,
    height: 57,
    backgroundColor: colorPalette.other.divider,
    alignSelf: 'center',
  }
});

interface InventoryStaticProps extends ViewProps {
  navigation?: any;
}

const InventoryStatic: React.FC<InventoryStaticProps> = ({ style, navigation }) => {
  const desktopCardBlockWidth = 832;
  const { saleData, visitData, totalUnitsData } = storeStaticMock;
  const lastUpdatedTime = new Date();
  const [justifyContent, setJustifyContent] = useState<'center' | 'space-between'>('space-between');

  const cardsResponsiveHandler = (layout: LayoutRectangle) => {
    setJustifyContent(layout.width >= desktopCardBlockWidth ? 'space-between' : 'center');
  };

  const data = [
    {
      name: "Drink",
      population: 21500000,
      color: colorPalette.primary,
      legendFontColor: "#7F7F7F",
      legendFontSize: 15
    },
    {
      name: "Food",
      population: 2800000,
      color: colorPalette.basic.curiousBlue,
      legendFontColor: "#7F7F7F",
      legendFontSize: 15
    },
    {
      name: "Household",
      population: 527612,
      color: colorPalette.basic.lightBlueGray,
      legendFontColor: "#7F7F7F",
      legendFontSize: 15
    },
  ];

  return (
    <View style={[style, styles.container]}>
      <View style={styles.headerContainer}>
        <View style={styles.titleContainer}>
          <Text style={[latoFontStyle.regular, { fontSize: 18 }]}>Inventory Statistic&nbsp;&nbsp;</Text>
          <Text style={[latoFontStyle.regular, { fontSize: 12, color: colorPalette.basic.dustyGray }]}>
            Last Updated : {moment(lastUpdatedTime).format(TIME_FORMAT)}
          </Text>
        </View>
        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Image source={require('../../assets/images/download.png')} style={{ width: 20, height: 20, marginRight: 18 }} />
            <View style={{ flexDirection: 'row', paddingVertical: 8, paddingHorizontal: 10, borderWidth: 1, borderRadius: 4, borderColor: colorPalette.basic.whisper, alignItems: 'center' }}>
              <Text style={{color: colorPalette.basic.lightBlack, fontSize: 14}}>Today</Text>
              <Image source={require('../../assets/images/calendar.png')} style={{ width: 14, height: 14, marginLeft: 10 }} />
            </View>
          </View>
        </View>
      </View>
      <View style={{flexDirection: 'row'}}>
        <PieChart
          data={data}
          width={400}
          height={200}
          chartConfig={{
            backgroundColor: "#0091EA",
            backgroundGradientFrom: "#0091EA",
            backgroundGradientTo: "#0091EA",
            color: (opacity = 1) => `rgba(${255}, ${255}, ${255}, ${opacity})`
          }}
          backgroundColor={"transparent"}
          accessor={'population'}
        />

        <View
          style={[styles.cards, { justifyContent }]}
          onLayout={event => cardsResponsiveHandler(event.nativeEvent.layout)}
        >
          <View style={{flexDirection: 'row'}}>
            <ChartCardMini
              title="Shrinkage Rate"
              unitsSymbol="$"
              value={40000}
              change={3.0}
              chartData={saleData}
              onPress={() => navigation.navigate('Sales')}
            />

            <View style={styles.divider} />

            <ChartCardMini
              title="Spoilage"
              value={345}
              change={-4.4}
              chartData={visitData}
              onPress={() => navigation.navigate('Sales')}
            />
          </View>
          <View style={{flexDirection: 'row'}}>
            <ChartCardMini
              title="Out of Stock"
              unitsSymbol="$"
              value={40000}
              change={3.0}
              chartData={saleData}
              onPress={() => navigation.navigate('Sales')}
            />

            <View style={styles.divider} />

            <ChartCardMini
              title="Number of Vendors"
              value={345}
              change={-4.4}
              chartData={visitData}
              onPress={() => navigation.navigate('Sales')}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default InventoryStatic;
