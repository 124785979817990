import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Menu, Avatar } from 'react-native-paper';

import { colorPalette } from '../styles/Theme';

const styles = StyleSheet.create({
  buttonLabel: {
    fontFamily: 'Lato_400Regular',
    fontSize: 24,
  },
});

interface IShop {
  name: string;
  storeId: string;
  image: any;
}

interface ShopSelectProps {
  value: string;
  items: Array<IShop>;
  onChange?: (store: IShop) => void;
}

const ShopSelect: React.FC<ShopSelectProps> = ({ value, items, onChange }) => {
  const [visible, setVisible] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState<IShop | undefined>(items.find(item => item.name === value));

  const openMenu = useCallback(() => setVisible(true), [setVisible]);
  const closeMenu = useCallback(() => setVisible(false), [setVisible]);

  const handleSelect = useCallback(
    (value: string) => {
      const store = items.find(item => item.name === value);
      setSelectedValue(store);
      closeMenu();
      if (typeof onChange === 'function') onChange(store!);
    },
    [items, closeMenu, onChange],
  );

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {selectedValue?.image && (
        // @ts-ignore
        <Avatar.Image size={50} source={selectedValue?.image} />
      )}
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          // @ts-ignore
          <Button uppercase={false} labelStyle={styles.buttonLabel} color={colorPalette.primary} onPress={openMenu}>
            {selectedValue?.name}&nbsp;&nbsp;▾
          </Button>
        }
      >
        {items
          .filter(({ name }) => name !== selectedValue?.name)
          .map(({ name }) => (
            <Menu.Item key={name} onPress={() => handleSelect(name)} title={name} />
          ))}
      </Menu>
    </View>
  );
};

export default React.memo(ShopSelect);
