import React, {useEffect, useState} from 'react';
import { Text, View, StyleSheet, ScrollView, ActivityIndicator } from 'react-native';
import StoreMonitor from '../components/StoreMonitor';
import VisitationDataTable from '../components/shopper/VisitationDataTable'
import CustomerVisitStatic from '../components/shopper/CustomerVisitStatic'
import { Unit } from '../models/Unit';
import { TableCellTypeEnum as TCTE } from '../enums/TableCellType.enum';
import { ICellSize } from '../interfaces/ICellSize';
import { useAppState } from '../contexts/AuthContext';
import { ProductService } from '../services/ProductService';
import { StoreService } from '../services/StoreService';
import { Bin } from '../models/Bin';
import { colorPalette } from '../styles/Theme';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 24,
    padding: 32,
    paddingVertical: 12,
  },
  tableContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: colorPalette.basic.white,
    borderRadius: 24,
    padding: 32,
    paddingVertical: 12,
    marginTop: 26,
  },
});

const ShopperScreen: React.FC = () => {
  const { token, storeId } = useAppState();
  const [productMap, setProductMap] = useState(new Map());
  const [units, setUnits] = useState<Array<Unit>>([]);
  const [tableData, setTableData] = useState<Array<Array<any>>>([]);

  const labels = ['Picture', 'ID', 'Duration', 'Check In', 'Check In', 'Item', 'Amount', 'Payment Status'];
  const customSizeMap = new Map<number, ICellSize>([[0, { fixed: 96 }]]);
  const cellTypes = [
    TCTE.Image,
    TCTE.Text,
    TCTE.Text,
    TCTE.Text,
    TCTE.Text,
    TCTE.Number,
    TCTE.Currency,
    TCTE.PaymentImage,
  ];

  useEffect(() => {
    if (!token || !storeId) return;
    ProductService.getAllProducts().then(data => {
      const productMap = new Map(data.map(p => [p.id, p]));
      setProductMap(productMap);
    });
    StoreService.getStoreUnits(storeId).then(setUnits);
  }, [token, storeId]);

  useEffect(() => {
    if (units.length) {
      const tableData: Array<Array<any>> = [];
      units.forEach((unit: Unit) => {
        const unitName = unit.name;
        unit.binList.forEach((bin: Bin) => {
          tableData.push([
            require('../../assets/images/picture.png'),
            `${Math.round(Math.random() * 456789)}t9`,
            '6:17 min',
            '08:21 AM',
            '08:21 AM',
            Math.round(Math.random() * 10),
            3565.35,
            Math.round(Math.random()) ? require('../../assets/images/payment-success.png') : require('../../assets/images/payment-error.png')
          ]);
        });
      });
      setTableData(tableData);
    }
  }, [productMap, units]);

  return (
    <View style={styles.container}>
      <ScrollView style={{ flex: 1, width: '100%' }}>
        <CustomerVisitStatic />
        <View style={styles.tableContainer}>
          {units.length && productMap.size && tableData.length ? (
            <VisitationDataTable dataset={tableData} labels={labels} customSizeMap={customSizeMap} cellTypes={cellTypes} />
          ) : (
            <ActivityIndicator size={26} color={colorPalette.primary} />
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default ShopperScreen;
