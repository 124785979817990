import React from 'react';
import {View, Text, TouchableOpacity, StyleSheet, Image} from 'react-native';
import { Avatar, Button, Divider } from 'react-native-paper';
import TitleWithBadge from '../elements/TitleWithBadge';
import { latoFontStyle } from '../../styles/Shared';
import { colorPalette } from '../../styles/Theme';
import { TIME_FORMAT } from '../../configs/Constants';
import moment from 'moment';

export const MostSoldProducts = () => {
  const styles = StyleSheet.create({
    container: {
      width: 230,
      backgroundColor: colorPalette.basic.white,
      paddingLeft: 20,
      paddingTop: 21,
      paddingRight: 20,
      paddingBottom: 14,
      borderRadius: 24,
      marginLeft: 37,
    },
    viewAllLabel: {
      fontSize: 12,
      color: colorPalette.basic.gunPowder,
    },
    title: {
      fontSize: 16,
      marginBottom: 4,
    },
  });

  const lastUpdatedTime = new Date();

  return (
    <View style={[styles.container]}>
      <Text style={styles.title}>Most Sold Products</Text>
      <Text style={[latoFontStyle.regular, { fontSize: 12, color: colorPalette.basic.dustyGray }]}>
        Last Updated : {moment(lastUpdatedTime).format(TIME_FORMAT)}
      </Text>
      <View style={{alignItems: 'flex-start'}}>
        <View style={{ flexDirection: 'row', paddingVertical: 8, paddingHorizontal: 10, marginRight: 32, borderWidth: 1, borderRadius: 4, borderColor: colorPalette.basic.whisper, alignItems: 'center', marginTop: 10, marginBottom: 35}}>
          <Text style={{color: colorPalette.basic.lightBlack, fontSize: 14}}>Today</Text>
          <Image source={require('../../../assets/images/calendar.png')} style={{ width: 14, height: 14, marginLeft: 10 }} />
        </View>
      </View>
      {Array(4).fill(0).map((v, index) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 33}}>
            <Text style={{color: colorPalette.primary}}>{index + 1}</Text>
            <Image source={require('../../../assets/images/drinks/coke.png')} style={{width: 25, height: 48, marginLeft: 20}} />
            <View style={{marginLeft: 30, justifyContent: 'center'}}>
              <Text style={{color: colorPalette.primary, fontSize: 24, fontWeight: '900'}}>$0000</Text>
              <Text style={{fontSize: 12}}>Name</Text>
            </View>
          </View>
        );
      })}
      <Text style={{textAlign: 'right', color: colorPalette.basic.coalGray, fontSize: 10}}>From last 7 days</Text>
    </View>
  );
};