import React from 'react';
import { View, Text, Image, StyleSheet, ViewProps, TouchableOpacity } from 'react-native';
import { LineChartData } from 'react-native-chart-kit/dist/line-chart/LineChart';
import { abbreviateNumber } from 'js-abbreviation-number';

import { colorPalette } from '../styles/Theme';
import { latoFontStyle, textStyles } from '../styles/Shared';
import LineChartMini from './LineChartMini';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colorPalette.basic.white,
    borderRadius: 16,
    marginHorizontal: 8,
    marginVertical: 4,
    paddingVertical: 16,
    paddingHorizontal: 24,
    maxHeight: 90,
    flex: 1,
    minWidth: 208,
    maxWidth: 248,
  },
  value: {
    marginBottom: 12,
  },
  chart: {
    width: '100%',
    maxWidth: 90,
    height: 36,
    transform: [{ scale: 0.3 }, { translateX: -120 }, { translateY: -30 }],
  },
  title: {
    fontSize: 14,
    color: colorPalette.basic.gunPowder,
  },
});

const getImage = (n: number) => {
  return n <= 0 ? require('../../assets/images/arrow-down.png') : require('../../assets/images/arrow-rise.png');
};

interface ChartCardMiniProps extends ViewProps {
  chartData: LineChartData;
  value: number;
  unitsSymbol?: string;
  title: string;
  change: number;
  onPress?: () => void;
}

const ChartCardMini: React.FC<ChartCardMiniProps> = ({
  style,
  chartData,
  title,
  change,
  value,
  unitsSymbol = '',
  onPress,
}) => {
  return (
    <TouchableOpacity style={[style, styles.container]} onPress={onPress}>
      <View style={{ flex: 1 }}>
      <Text style={[latoFontStyle.regular, styles.title, {marginBottom: 20}]}>{title}</Text>
        <Text style={[latoFontStyle.black, textStyles.currencyValue, styles.value]}>
          {unitsSymbol}
          {abbreviateNumber(value, 0)}
        </Text>
        
      </View>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <View style={styles.chart}>
          <LineChartMini data={chartData} />
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text
            style={[
              latoFontStyle.regular,
              {
                fontSize: 18,
                color: change <= 0 ? colorPalette.basic.coral : colorPalette.primary,
              },
            ]}
          >
            {(change <= 0 ? '' : '+') + change}%
          </Text>
          <Image source={getImage(change)} style={{width: 11, height: 12, marginLeft: 6}} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default ChartCardMini;
