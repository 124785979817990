import React from 'react';
import { createAppContainer } from 'react-navigation';

import createSidebarNavigator from './SidebarTabsNavigator';
import { routeConfigMap } from '../../routes/routes';

const sidebarNavigator = createSidebarNavigator(routeConfigMap, {
  initialRouteName: 'Dashboard',
});

export default createAppContainer(sidebarNavigator);
