import Amplify, { Auth } from 'aws-amplify';
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwt_decode from 'jwt-decode';
import config from './awsCognitoConfig';
import { StringObject } from '../interfaces/StringObject';
import moment from 'moment';

export class AuthClient {
  private readonly username: string;
  private readonly password: string;
  private readonly storeKey = 'accessToken';
  private static instance: AuthClient;

  constructor(username: string, password: string) {
    Amplify.configure(config);
    this.username = username;
    this.password = password;
  }

  public static getInstance(username: string, password: string): AuthClient {
    if (!AuthClient.instance) {
      AuthClient.instance = new AuthClient(username, password);
    }

    return AuthClient.instance;
  }

  public static async isTokenValid(token: string): Promise<boolean> {
    const tokenInfo = AuthClient.getTokenInfo(token);
    const expirationDate = new Date(Number(tokenInfo.exp) * 1000);
    return moment(expirationDate).isAfter(moment());
  }

  private async signIn(username: string, password: string): Promise<boolean> {
    try {
      await Auth.signIn(username, password);
    } catch (error) {
      console.log('error signing in', error);
      return false;
    }

    await Auth.currentSession().then(async data => {
      await AsyncStorage.setItem(this.storeKey, data.getAccessToken().getJwtToken());
    });
    return true;
  }

  public static getTokenInfo(jwtToken: string): StringObject {
    const decoded: any = jwt_decode(jwtToken);
    return {
      token: jwtToken,
      username: decoded.username,
      exp: decoded.exp,
    };
  }

  public async getToken(): Promise<string> {
    const storedToken = await AsyncStorage.getItem(this.storeKey);
    if (!storedToken || (storedToken && !(await AuthClient.isTokenValid(storedToken)))) {
      await this.signIn(this.username, this.password);
    }
    return (await AsyncStorage.getItem(this.storeKey)) || '';
  }
}
