import React from 'react';
import { LineChart } from 'react-native-chart-kit';
import { LineChartData } from 'react-native-chart-kit/dist/line-chart/LineChart';

interface LineChartMiniProps {
  data: LineChartData;
  bezier?: boolean;
  width?: number;
  height?: number;
}

const LineChartMini: React.FC<LineChartMiniProps> = ({ data, width = 300, height = 100, bezier = true }) => {
  return (
    <LineChart
      data={data}
      width={width}
      height={height}
      withVerticalLabels={false}
      withVerticalLines={false}
      withHorizontalLabels={false}
      withHorizontalLines={false}
      withDots={false}
      withShadow={false}
      transparent={true}
      bezier={bezier}
      chartConfig={{
        color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      }}
    />
  );
};

export default LineChartMini;
