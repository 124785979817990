import { StyleSheet } from 'react-native';
import { colorPalette } from './Theme';

export const labelStyle = StyleSheet.create({
  label: {
    fontFamily: 'Lato_400Regular',
    fontSize: 14,
    lineHeight: 24,
    marginTop: 4,
    marginBottom: 4,
  },
});

export const latoFontStyle = StyleSheet.create({
  regular: {
    fontFamily: 'Lato_400Regular',
  },
  bold: {
    fontFamily: 'Lato_700Bold',
  },
  black: {
    fontFamily: 'Lato_900Black',
  },
});

export const screenStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const textStyles = StyleSheet.create({
  currencyValue: {
    ...latoFontStyle.black,
    fontSize: 24,
    color: colorPalette.primary,
    textTransform: 'lowercase',
  },
});
