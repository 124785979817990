export enum TableCellTypeEnum {
  Text,
  Number,
  LowStock,
  Image,
  ProductImage,
  Currency,
  Hidden,
  PaymentImage,
}
