import React, { useEffect, useState } from 'react';
import useCancelToken from 'react-use-cancel-token';

import { Image, ImageResizeMode, ImageStyle, StyleProp } from 'react-native';
import { ProductService } from '../../services/ProductService';

interface ProductImageProps {
  productId: string;
  height: number;
  style?: StyleProp<ImageStyle>;
  resizeMode: ImageResizeMode;
}

const productImage = require('../../../assets/images/product-image.png');

const ProductImage: React.FC<ProductImageProps> = ({ style, height, resizeMode, productId }) => {
  const [source, setSource] = useState(productImage);
  const { newCancelToken, cancelPreviousRequest } = useCancelToken();

  useEffect(() => {
    ProductService.getProductImage(productId, newCancelToken()).then(setSource);
    return cancelPreviousRequest;
  }, [newCancelToken, cancelPreviousRequest, productId]);

  return <Image style={style} height={height} resizeMode={resizeMode} source={source} />;
};

export default React.memo(ProductImage);
