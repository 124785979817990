import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { DeviceEventEmitter } from 'react-native';

const apiClient: AxiosInstance = axios.create();
apiClient.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-ua-string': 'PickNGo/1.0 Dashboard Client',
      'x-auth-token': apiClient.defaults.headers.common['x-auth-token'],
    };
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: any) => {
    if (error.response?.status === 400 && error.response.data.error.code === 'auth/expiredAuthentification') {
      DeviceEventEmitter.emit('tokenExpired', {});
    }
    return Promise.reject(error);
  },
);

apiClient.defaults.baseURL = 'https://pickngo.arcfx.net/api/v1/';

export default apiClient;
