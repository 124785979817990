import React from 'react';
import {View, Text, TouchableOpacity, StyleSheet, ViewProps} from 'react-native';
import { Avatar, Button, Divider } from 'react-native-paper';
import TitleWithBadge from '../elements/TitleWithBadge';
import { latoFontStyle } from '../../styles/Shared';
import { colorPalette } from '../../styles/Theme';
import { User } from '../../models/User';
import moment from 'moment';

interface CustomersInStoreProps extends ViewProps {
  items: Array<User>;
  onHeaderClick: () => void;
  countToPreview?: number;
  isLoading: boolean;
}

export const CustomersInStore = ({
  items,
  style,
  countToPreview = 6,
  onHeaderClick,
  isLoading,
}: CustomersInStoreProps) => {
  const styles = StyleSheet.create({
    container: {
      position: 'relative',
      flexDirection: 'column',
      backgroundColor: colorPalette.basic.white,
      paddingLeft: 20,
      paddingTop: 21,
      paddingBottom: 25,
      borderRadius: 24,
    },
    viewAllLabel: {
      fontSize: 12,
      color: colorPalette.basic.gunPowder,
    },
    divider: {
      width: 0.5,
      height: 57,
      backgroundColor: colorPalette.other.divider,
      alignSelf: 'center',
    }
  });

  return (
    <View style={[style, styles.container]}>
      <TitleWithBadge title="Customers in Store" count={3} isLoading={false} />
      <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingRight: 20}}>
        <View style={{alignItems: 'center', flex: 1}}>
          <Text style={{color: colorPalette.primary, fontFamily: latoFontStyle.regular.fontFamily, fontSize: 24, fontWeight: '900'}}>2</Text>
          <Text style={{textAlign: 'center', marginTop: 13, fontSize: 12, fontFamily: latoFontStyle.regular.fontFamily}}>New{'\n'}Customer</Text>
        </View>
        <View style={styles.divider}/>
        <View style={{alignItems: 'center', flex: 1}}>
          <Text style={{color: colorPalette.primary, fontFamily: latoFontStyle.regular.fontFamily, fontSize: 24, fontWeight: '900'}}>1</Text>
          <Text style={{textAlign: 'center', marginTop: 13, fontSize: 12, fontFamily: latoFontStyle.regular.fontFamily}}>Recurrent{'\n'}Customer</Text>
        </View>
      </View>
    </View>
  );
};