import React from 'react';
// @ts-ignore
import SwitchToggle from 'react-native-switch-toggle';
import { Text } from 'react-native';
import { labelStyle } from '../../styles/Shared';
import { colorPalette } from '../../styles/Theme';

interface PngSwitchProps {
  label?: string;
  value?: boolean;
  onChange: (val: boolean) => void;
}

const PngSwitch: React.FC<PngSwitchProps> = ({ value = false, label, onChange }) => {
  return (
    <React.Fragment>
      {label && <Text style={labelStyle.label}>{label}</Text>}
      <SwitchToggle
        switchOn={value}
        circleColorOff={colorPalette.basic.white}
        circleColorOn={colorPalette.primary}
        backgroundColorOn={colorPalette.basic.mystic}
        backgroundColorOff={colorPalette.basic.mystic}
        containerStyle={{
          width: 40,
          height: 20,
          borderRadius: 25,
          padding: 4,
        }}
        circleStyle={{
          width: 13,
          height: 13,
          borderRadius: 13,
        }}
        onPress={() => onChange(!value)}
      />
    </React.Fragment>
  );
};

export default React.memo(PngSwitch);
