import React from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  Image,
  ScrollView,
  SafeAreaView,
  StatusBar,
  useWindowDimensions,
} from 'react-native';

import { colorPalette } from '../../styles/Theme';
import { Badge } from 'react-native-paper';
import Icon from '../../helpers/Icons';
import { IPAD_AIR_WIDTH } from '../../configs/Constants';

const styles = StyleSheet.create({
  tab: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 42,
    width: '100%',
    marginVertical: 6,
    backgroundColor: 'transparent',
    overflow: 'hidden',
    paddingLeft: 30,
    paddingRight: 12,
  },
  tabContainer: {
    justifyContent: 'flex-start',
    height: '100%',
    paddingBottom: 24,
    width: 206,
    backgroundColor: colorPalette.other.bg,
    paddingTop: StatusBar.currentHeight,
  },
  group: {
    marginTop: 36,
    marginBottom: 10,
    marginHorizontal: 24,
    fontFamily: 'Lato_700Bold',
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'uppercase',
    color: colorPalette.basic.gray,
    textAlign: 'left',
  },
  icon: {
    zIndex: 0,
  },
  link: {
    marginHorizontal: 16,
  },
  logo: {
    width: '100%',
    height: 28,
    maxHeight: 28,
    resizeMode: 'contain',
    marginTop: 52,
    marginBottom: 16,
  },
  highlight: {
    position: 'absolute',
    left: 0,
    width: 64,
    height: 45,
    backgroundColor: colorPalette.primary,
    borderTopRightRadius: 45,
    borderBottomRightRadius: 45,
  },
  badge: {
    fontFamily: 'Lato_900Black',
    fontWeight: '900',
    fontSize: 10,
    color: colorPalette.basic.white,
    backgroundColor: colorPalette.primary,
  },
  hr: {
    width: 34,
    marginVertical: 40,
    marginHorizontal: 24,
    height: 1,
    backgroundColor: colorPalette.basic.gray,
  },
  mainMenuMobileGroup: {
    backgroundColor: 'transparent',
    marginTop: 0,
  },
});

const SidebarTabs: React.FC<any> = ({ navigation, descriptors }) => {
  const { routes, index } = navigation.state;
  const renderedGroups: Array<string> = [];
  const window = useWindowDimensions();
  const isMinimized = window.width <= IPAD_AIR_WIDTH;

  return (
    <SafeAreaView style={[styles.tabContainer, { maxWidth: isMinimized ? 72 : 206 }]}>
      {isMinimized ? (
        <View style={[styles.logo, { justifyContent: 'center', alignItems: 'center' }]}>
          <Text style={{ width: 'auto', fontSize: 24, color: colorPalette.primary }}>B</Text>
        </View>
      ) : (
        <Image style={styles.logo} source={require('../../../assets/images/bodega.png')} />
      )}

      <ScrollView>
        {routes.map((route: any, tabIndex: number) => {
          const { routeName, params } = route;
          const { icon, tabName, group, notificationCount } = params || {};
          const isActive = tabIndex === index;
          const color = isActive ? colorPalette.other.linkActive : colorPalette.other.link;
          const isGroupTitleNeeded = !renderedGroups.includes(group);
          if (renderedGroups) {
            renderedGroups.push(group);
          }

          return (
            <React.Fragment key={group + route.routeName}>
              {isGroupTitleNeeded &&
                (isMinimized ? (
                  <View style={[styles.hr, group === 'Main Menu' ? styles.mainMenuMobileGroup : {}]} />
                ) : (
                  <Text style={styles.group}>{group}</Text>
                ))}
              <TouchableOpacity onPress={() => navigation.navigate(routeName)} style={styles.tab} key={route.routeName}>
                {isActive && <View style={styles.highlight} />}
                <Icon
                  style={styles.icon}
                  name={icon}
                  size={24}
                  color={isActive ? colorPalette.basic.white : colorPalette.other.link}
                />
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: isMinimized ? 'flex-start' : 'space-between',
                  }}
                >
                  {!isMinimized && (
                    <Text style={[styles.link, { color, fontWeight: isActive ? 'bold' : 'normal' }]}>{tabName}</Text>
                  )}
                  {notificationCount > 0 && !isActive && (
                    <Badge style={[styles.badge, { transform: [{ translateX: -8 }] }]} size={18}>
                      {notificationCount}
                    </Badge>
                  )}
                </View>
              </TouchableOpacity>
            </React.Fragment>
          );
        })}
      </ScrollView>
    </SafeAreaView>
  );
};

export default SidebarTabs;
