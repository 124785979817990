import React from 'react';
import { StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native';
import { colorPalette } from '../styles/Theme';
import Icon from '../helpers/Icons';
import ProfileMenu from './ProfileMenu';
import ShopSelect from './ShoopSelect';
import { Badge } from 'react-native-paper';
import { useAppState } from '../contexts/AuthContext';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingTop: StatusBar.currentHeight,
    marginVertical: 42,
    marginLeft: 36,
  },
  rightSide: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 220,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 28,
  },
  notificationBadge: {
    position: 'absolute',
    right: 2,
    top: 2,
    backgroundColor: colorPalette.other.notification,
    transform: [{ scale: 0.75 }],
  },
});

const MainHeader: React.FC = () => {
  const { setStoreId } = useAppState();
  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <ShopSelect
          value="San Jose"
          items={[
            {
              name: 'Richmond',
              storeId: '5e72ec2a21be024732b7c58b',
              image: require('../../assets/images/shop-logo.png'),
            },
            {
              name: 'San Jose',
              storeId: '5e72ec2a21be024732b7c58b',
              image: require('../../assets/images/shop-logo-1.png'),
            },
            {
              name: 'Marietta',
              storeId: '5e72ec2a21be024732b7c58b',
              image: require('../../assets/images/shop-logo.png'),
            },
            {
              name: 'Atlanta',
              storeId: '5e72ec2a21be024732b7c58b',
              image: require('../../assets/images/shop-logo-1.png'),
            },
          ]}
          onChange={store => setStoreId!(store.storeId)}
        />
      </View>
      <View style={[styles.rightSide]}>
        <TouchableOpacity>
          <Icon name="notification" size={18} style={{ position: 'relative' }} color={colorPalette.other.link} />
          <Badge style={styles.notificationBadge} size={8} />
        </TouchableOpacity>
        <View style={styles.profileContainer}>
          <ProfileMenu name="Jamet Roy" image={require('../../assets/images/avatar.png')} />
        </View>
      </View>
    </View>
  );
};

export default React.memo(MainHeader);
