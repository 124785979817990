import { Expose, Type } from 'class-transformer';
import { Hardware } from './Hardware';
import { Bin } from './Bin';
import { BIN_LOW_STOCK_COUNT, VALID_STATUS } from '../configs/Constants';
import { Coordinates } from './Coordinates';
import { ProblemTypeEnum } from '../enums/ProblemType.enum';

export class Unit {
  @Expose()
  public id: string;

  @Expose()
  public storeId: string;

  @Expose()
  public brandId: string;

  @Expose()
  public name: string;

  @Expose()
  public status: string;

  @Type(() => Hardware)
  public hwList: Hardware[] = [];

  @Type(() => Bin)
  public binList: Bin[] = [];

  public planPosition: Coordinates = new Coordinates(0, 0);

  constructor(id: string, storeId: string, brandId: string, name: string, status: string) {
    this.id = id;
    this.storeId = storeId;
    this.brandId = brandId;
    this.name = name;
    this.status = status;
  }

  public getHwProblemCount(): number {
    return this.hwList.reduce((accum: number, item: Hardware) => accum + (item.status === VALID_STATUS ? 0 : 1), 0);
  }

  public getBinHardwareProblemCount(): number {
    return this.binList.reduce((accum: number, item: Bin) => accum + (item.status === VALID_STATUS ? 0 : 1), 0);
  }

  public getBinLowStockProblemCount(): number {
    return this.binList.reduce(
      (accum: number, item: Bin) => accum + (item.productCount > BIN_LOW_STOCK_COUNT ? 0 : 1),
      0,
    );
  }

  public calculateProblemCount(): number {
    const binHardwareProblemCount = this.getBinHardwareProblemCount();
    const binLowStockProblemCount = this.getBinLowStockProblemCount();
    const hwProblemCount = this.getHwProblemCount();
    return hwProblemCount + binHardwareProblemCount + binLowStockProblemCount;
  }

  get primaryProblem(): ProblemTypeEnum | undefined {
    const binHardwareProblemCount = this.getBinHardwareProblemCount();
    const binLowStockProblemCount = this.getBinLowStockProblemCount();
    const hwProblemCount = this.getHwProblemCount();
    const hwGeneralProblems = hwProblemCount + binHardwareProblemCount;
    const allProblems = hwGeneralProblems + binLowStockProblemCount;
    if (allProblems <= 0) return undefined;
    if (allProblems > 0) {
      return hwGeneralProblems > 0 ? ProblemTypeEnum.System : ProblemTypeEnum.LowStock;
    }
  }
}
