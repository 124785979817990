import React from 'react';
import {
  createNavigator,
  TabRouter,
  SafeAreaView,
  NavigationRouteConfigMap,
  NavigationTabRouterConfig,
} from 'react-navigation';

import SidebarTabs from './SidebarTabs';
import { StyleSheet, View } from 'react-native';
import MainHeader from '../MainHeader';
import { colorPalette } from '../../styles/Theme';

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
    minWidth: 768,
  },
});

const SidebarTabsNavigator: React.FC<any> = ({ navigation, descriptors }) => {
  const { routes, index } = navigation.state;
  const descriptor = descriptors[routes[index].key];
  const ActiveScreen = descriptor.getComponent();

  return (
    <SafeAreaView style={styles.content}>
      <SidebarTabs descriptors={descriptors} navigation={navigation} />
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          backgroundColor: colorPalette.other.bg,
        }}
      >
        <MainHeader />
        <ActiveScreen navigation={descriptor.navigation} />
      </View>
    </SafeAreaView>
  );
};

const createSidebarNavigator = (
  routeConfigMap: NavigationRouteConfigMap<any, any>,
  sidebarNavigatorConfig: NavigationTabRouterConfig,
) => {
  const customTabRouter = TabRouter(routeConfigMap, sidebarNavigatorConfig);

  return createNavigator(SidebarTabsNavigator, customTabRouter, {});
};

export default createSidebarNavigator;
