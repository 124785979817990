import { useCallback } from 'react';
import { ProblemTypeEnum } from '../enums/ProblemType.enum';
import { colorPalette } from '../styles/Theme';

const useDataUpdateInterval = () => {
  const getColorByProblemType = useCallback((problemType?: ProblemTypeEnum) => {
    switch (problemType) {
      case ProblemTypeEnum.LowStock:
        return colorPalette.basic.salmon;
      case ProblemTypeEnum.Misplace:
        return colorPalette.basic.curiousBlue;
      case ProblemTypeEnum.System:
        return colorPalette.basic.heliotrope;
      default:
        return colorPalette.basic.salmon;
    }
  }, []);

  const isProblemMultiTypes = useCallback((lowStockCount, systemCount) => {
    return lowStockCount > 0 && systemCount > 0;
  }, []);

  return { getColorByProblemType, isProblemMultiTypes };
};

export default useDataUpdateInterval;
