import React from 'react';
import { ActivityIndicator, StyleProp, StyleSheet, Text, TextStyle, View, ViewProps } from 'react-native';
import { latoFontStyle } from '../../styles/Shared';
import { Badge } from 'react-native-paper';
import { colorPalette } from '../../styles/Theme';

const styles = StyleSheet.create({
  titleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  title: {
    fontSize: 16,
    flex: 5,
    lineHeight: 26,
  },
  badge: {
    alignSelf: 'center',
    fontSize: 16,
    color: colorPalette.basic.white,
    backgroundColor: colorPalette.primary,
  },
});

interface TitleWithBadgeProps extends ViewProps {
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  count: number;
  isLoading?: boolean;
}

const TitleWithBadge: React.FC<TitleWithBadgeProps> = ({ style, title, titleStyle, isLoading, count }) => {
  return (
    <View style={[styles.titleWrapper, style]}>
      <Text style={[latoFontStyle.regular, styles.title, titleStyle]}>{title}</Text>
      <View style={{ flex: 2 }}>
        {isLoading ? (
          <ActivityIndicator size={26} color={colorPalette.primary} />
        ) : (
          <Badge size={26} style={[latoFontStyle.regular, styles.badge]}>
            {count}
          </Badge>
        )}
      </View>
    </View>
  );
};

export default React.memo(TitleWithBadge);
