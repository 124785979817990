import apiClient from '../utils/ApiClient';
import { AxiosResponse, CancelToken } from 'axios';
import { plainToClass } from 'class-transformer';

import { Product } from '../models/Product';

export class ProductService {
  public static imageMap = new Map<string, any>();

  public static async getProductById(id: string, cancelToken?: CancelToken): Promise<Product> {
    const config = typeof cancelToken === 'function' ? { cancelToken: cancelToken } : {};
    return apiClient.get(`/products/${id}`, config).then((response: AxiosResponse) => {
      const product = plainToClass(Product, response.data.data.product as {});
      ProductService.imageMap.set(id, product.imageUrl);
      return product;
    });
  }

  public static async getAllProducts(): Promise<Array<Product>> {
    return apiClient.get('products').then((response: AxiosResponse) => plainToClass(Product, response.data.data));
  }

  public static async getProductImage(productId: string, cancelToken?: CancelToken): Promise<any> {
    return ProductService.imageMap.has(productId)
      ? ProductService.imageMap.get(productId)
      : (await ProductService.getProductById(productId, cancelToken)).imageUrl;
  }
}
