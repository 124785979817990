import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import ShopperInquiries from '../components/dashboard/ShopperInquiries';
import {CustomersInStore} from '../components/dashboard/CustomersInStore';
import {EmployeesInStore} from '../components/dashboard/EmployeesInStore';
import {LowStock} from '../components/dashboard/LowStock';
import { plan, shopperInquiriesMock } from '../mocks/MockData';
import StoreStatic from '../components/StoreStatic';
import ShelveMonitor from '../components/dashboard/ShelveMonitor';
import { useAppState } from '../contexts/AuthContext';
import { User } from '../models/User';
import { StoreService } from '../services/StoreService';
import useDataUpdateInterval from '../hooks/useDataUpdateInterval';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 36,
  },
});

const HomeScreen: React.FC<any> = ({ navigation }) => {
  const [shopperInquiries] = useState(shopperInquiriesMock.slice());
  const [customersInStore, setCustomersInStore] = useState<Array<User>>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { token, storeId } = useAppState();

  const dataUpdateHandler = useCallback(() => {
    if (!token || !storeId) return;
    setIsDataLoading(true);
    StoreService.getUsersInStore(storeId)
      .then(users => setCustomersInStore(users))
      .finally(() => setIsDataLoading(false));
  }, [token, storeId]);

  useDataUpdateInterval(dataUpdateHandler);

  useEffect(() => {
    dataUpdateHandler();
  }, [dataUpdateHandler]);

  return (
    <View style={styles.container}>
      <ScrollView style={{ flex: 1 }}>
        <StoreStatic navigation={navigation} />
        <ShelveMonitor style={{ marginTop: 14, marginBottom: 32 }} plan={plan} />
      </ScrollView>

      <ScrollView
        style={{
          flex: 0,
          flexBasis: 296,
          maxWidth: 296,
          paddingHorizontal: 24,
        }}
      >
        <EmployeesInStore />
        <CustomersInStore
          onHeaderClick={() => navigation.navigate('Shopper')}
          style={{ marginTop: 32 }}
          items={customersInStore}
          isLoading={isDataLoading}
        />
        <LowStock
          style={{ marginTop: 32 }}
        />
      </ScrollView>
    </View>
  );
};

export default HomeScreen;
