import { DefaultTheme } from 'react-native-paper';

export const colorPalette = {
  primary: '#3C3B9D',
  secondary: '#595959',
  basic: {
    black: '#000000',
    white: '#ffffff',
    whisper: '#ECE9F1',
    athensGray: '#DFE0EB',
    gray: '#909090',
    dustyGray: '#979797',
    santasGray: '#9FA2B4',
    boulder: '#757575',
    regentGray: '#78909C',
    alto: '#d2d2d2',
    mystic: '#DFE5EC',
    green: '#28C76F',
    curiousBlue: '#29ABE2',
    downriver: '#0C1E5B',
    smoky: '#5E5873',
    silverChalice: '#AAAAAA',
    gunPowder: '#464255',
    alabaster: '#F9F9F9',
    coral: '#FE8658',
    salmon: '#FE7875',
    heliotrope: '#9B69F6',
    coralRed: '#FF444D',
    lightBlack: '#11263C',
    lightBlueGray: '#A3AED0',
    coalGray: '#8790A3',
    greyFourty: '#A2A3A5',
    cyberPurple: '#6449C5',
  },
  other: {
    border: '#d2d2d2',
    bg: '#f3f4fa',
    linkActive: '#3C3B9D',
    link: '#384A5D',
    notification: '#FA5F1C',
    divider: '#E6E7E9',
  },
};

const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: colorPalette.primary,
  },
};

export default theme;
