import React, { useState } from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import SalesChart from '../SalesChart';
import ChartCardMini from '../ChartCardMini';
import { latoFontStyle } from '../../styles/Shared';
import { colorPalette } from '../../styles/Theme';
import moment from 'moment';
import { TIME_FORMAT } from '../../configs/Constants';
import DatePicker from '../elements/DatePicker';
import { salesStaticMockData, storeStaticMock } from '../../mocks/MockData';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colorPalette.basic.white,
    borderRadius: 24,
    padding: 32,
    paddingBottom: 14,
    // maxHeight: 360,
  },
  headerContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    flex: 1,
    flexWrap: 'wrap',
  },
  chartContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  divider: {
    width: 0.5,
    height: 57,
    backgroundColor: colorPalette.other.divider,
    alignSelf: 'center',
  }
});

export const SaleStatic: React.FC = () => {
  const [chartWidth, setChartWidth] = useState(0);
  const lastUpdatedTime = new Date();

  const { saleData, totalUnitsData } = storeStaticMock;

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View style={{ flex: 1 }} onLayout={event => setChartWidth(event.nativeEvent.layout.width)}>
          <View style={styles.headerContainer}>
            <View style={styles.titleContainer}>
              <Text style={[latoFontStyle.regular, { fontSize: 18 }]}>Store Sale Statistic&nbsp;&nbsp;</Text>
              <Text style={[latoFontStyle.regular, { fontSize: 12, color: colorPalette.basic.dustyGray }]}>
                Last Updated : {moment(lastUpdatedTime).format(TIME_FORMAT)}
              </Text>
            </View>
            <View style={styles.chartContainer}>
              <Image source={require('../../../assets/images/search.png')} style={{width: 16, height: 16, marginRight: 13}} />
              <Image source={require('../../../assets/images/download.png')} style={{width: 18, height: 18, marginRight: 18}} />
              <View style={{ flexDirection: 'row', paddingVertical: 8, paddingHorizontal: 10, marginRight: 32, borderWidth: 1, borderRadius: 4, borderColor: colorPalette.basic.whisper, alignItems: 'center'}}>
                <Text style={{color: colorPalette.basic.lightBlack, fontSize: 14}}>Today</Text>
                <Image source={require('../../../assets/images/calendar.png')} style={{ width: 14, height: 14, marginLeft: 10 }} />
              </View>
            </View>
          </View>
          <SalesChart data={salesStaticMockData} width={chartWidth} />
          <View style={{flexDirection: 'row', alignSelf: 'center', marginTop: 28}}>
            <ChartCardMini
              title="Total sale"
              unitsSymbol="$"
              value={40000}
              change={3.0}
              chartData={saleData}
              onPress={() => {}}
            />

            <View style={styles.divider} />

            <ChartCardMini
              title="Total units sold"
              value={345}
              change={-4.4}
              chartData={totalUnitsData}
              onPress={() => {}}
            />
          </View>
          <Text style={{textAlign: 'right', color: colorPalette.basic.coalGray, fontSize: 10}}>From last 7 days</Text>
        </View>
      </View>
    </View>
  );
};

export default SaleStatic;
