import {
  HomeScreen,
  InventoryScreen,
  SalesScreen,
  SettingsScreen,
  ShopperScreen,
  SystemScreen,
  StoreSetupScreen,
} from '../screens';

export const routeConfigMap = {
  Dashboard: {
    screen: HomeScreen,
    params: {
      group: 'Main Menu',
      icon: 'grid',
      tabName: 'Dashboard',
    },
  },
  Inventory: {
    screen: InventoryScreen,
    params: {
      group: 'Main Menu',
      icon: 'clipboard-check',
      tabName: 'Inventory',
      notificationCount: 4,
    },
  },
  Sales: {
    screen: SalesScreen,
    params: {
      group: 'Main Menu',
      icon: 'bar-chart',
      tabName: 'Sales',
      notificationCount: 1,
    },
  },
  Shopper: {
    screen: ShopperScreen,
    params: {
      group: 'Main Menu',
      icon: 'user',
      tabName: 'Shopper',
    },
  },
  System: {
    screen: SystemScreen,
    params: {
      group: 'Main Menu',
      icon: 'database',
      tabName: 'System',
      notificationCount: 1,
    },
  },
  StoreSetup: {
    screen: StoreSetupScreen,
    params: {
      group: 'Control Panel',
      icon: 'store',
      tabName: 'StoreSetup',
    },
  },
  Settings: {
    screen: SettingsScreen,
    params: {
      group: 'Control Panel',
      icon: 'settings',
      tabName: 'Settings',
    },
  },
  // StyleGuideScreen: {
  //   screen: StyleGuideScreen,
  //   params: {
  //     group: 'Control Panel',
  //     icon: 'book',
  //     tabName: 'Style Guide',
  //   }
  // },
};
