import { Expose } from 'class-transformer';

export class User {
  @Expose({ name: 'userId' })
  public id: string;

  @Expose({ name: 'userName' })
  public nickName: string;

  public dateTime = new Date();
  public avatar: any;

  constructor(id: string, name: string) {
    this.id = id;
    this.nickName = name;
  }
}
