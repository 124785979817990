import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import DelayInput from 'react-native-debounce-input';

import { colorPalette } from '../../styles/Theme';
import { TableCellTypeEnum as TCTE } from '../../enums/TableCellType.enum';
import { latoFontStyle } from '../../styles/Shared';
import { IconButton } from 'react-native-paper';
import Pagination from '../elements/Pagination';
import { ICellSize } from '../../interfaces/ICellSize';
import Table, { FilterConfig } from '../elements/Table';
import { SortStateEnum } from '../../enums/SortState.enum';

import * as _ from 'lodash';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  productHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  title: {
    ...latoFontStyle.regular,
    fontSize: 18,
  },
  pagination: {
    justifyContent: 'flex-end',
  },
  filter: {
    backgroundColor: colorPalette.basic.whisper,
    minWidth: 320,
    color: colorPalette.basic.black,
    paddingHorizontal: 8,
  },
});

interface SalesTableProps {
  labels: Array<string>;
  customSizeMap?: Map<number, ICellSize>;
  cellTypes: Array<TCTE>;
  dataset: Array<Array<any>>;
}

const nameCollIndex = 1;
const unitCollIndex = 8;
const locationCollIndex = 5;

const SalesTable: React.FC<SalesTableProps> = ({ labels, customSizeMap, cellTypes, dataset }) => {
  const [isSearchEnabled, setSearchEnabled] = useState(false);
  const [filter, setFilter] = useState<string>('');
  const [filterUnit, setFilterInit] = useState<string>('All');
  const [sortState, setSortState] = useState<{ index: number; state: SortStateEnum } | undefined>();
  const [totalItems, setTotalItems] = useState<number>(dataset.length);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const lastIndex = currentPage * pageSize;
  const [data, setData] = useState(dataset.slice(lastIndex - pageSize, lastIndex));

  const processDataForTable = (page: number, size: number, preparedDataset?: Array<Array<any>>) => {
    let allData = preparedDataset || dataset;
    if (filter) {
      allData = allData.filter((row: Array<any>) => row[nameCollIndex].toLowerCase().includes(filter.toLowerCase()));
    }

    if (filterUnit && filterUnit !== 'All') {
      allData = allData.filter(row => row[unitCollIndex] === filterUnit);
    }

    if (sortState?.index !== undefined && sortState.state !== SortStateEnum.DEFAULT) {
      allData =
        sortState.state === SortStateEnum.DESC
          ? _.orderBy(allData, [sortState.index], ['desc'])
          : _.orderBy(allData, [sortState.index], ['asc']);
    }

    const lastIndex = page * size;
    setTotalItems(allData.length);
    setData([]);
    setData(allData.slice(lastIndex - size, lastIndex));
  };

  const filterConfigMap = new Map<number, FilterConfig>([
    [
      locationCollIndex,
      {
        availableValues: ['All', ...Array.from(new Set(dataset.map(row => row[unitCollIndex])))],
        changeHandler: value => setFilterInit(value.toString()),
      },
    ],
  ]);

  useEffect(() => {
    if (!isSearchEnabled) {
      setFilter('');
    }
  }, [isSearchEnabled, setFilter]);

  useEffect(() => {
    processDataForTable(currentPage, pageSize);
  }, [currentPage, pageSize, sortState, filter, filterUnit]);

  const onPageSizeHandler = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const onSortChange = (index?: number, state?: SortStateEnum) => {
    setSortState(index === undefined ? undefined : { index, state: state || SortStateEnum.DEFAULT });
  };

  return (
    <View style={styles.container}>
      <View style={styles.productHeader}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.title}>Sales &nbsp;</Text>
          <Text style={[styles.title, { color: colorPalette.basic.gray }]}>{totalItems} Items</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flexDirection: 'row', paddingVertical: 8, paddingHorizontal: 10, marginRight: 32, borderWidth: 1, borderRadius: 4, borderColor: colorPalette.basic.whisper, alignItems: 'center'}}>
            <Text style={{color: colorPalette.basic.lightBlack, fontSize: 14}}>Today</Text>
            <Image source={require('../../../assets/images/calendar.png')} style={{ width: 14, height: 14, marginLeft: 10 }} />
          </View>
          {isSearchEnabled && (
            <DelayInput
              value={filter.toString()}
              minLength={2}
              onChangeText={value => setFilter(value.toString())}
              delayTimeout={500}
              style={styles.filter}
            />
          )}
          {/* @ts-ignore */}
          <IconButton
            size={24}
            color={colorPalette.basic.black}
            icon={isSearchEnabled ? 'close' : 'table-search'}
            onPress={() => setSearchEnabled(!isSearchEnabled)}
          />
          {/* @ts-ignore */}
          <IconButton size={24} color={colorPalette.basic.black} icon="dots-vertical" />
        </View>
      </View>

      <Table
        dataset={data}
        labels={labels}
        customSizeMap={customSizeMap}
        filterConfigMap={filterConfigMap}
        cellTypes={cellTypes}
        onSortChange={onSortChange}
      />

      <View style={styles.pagination}>
        <Pagination
          totalItemCount={totalItems}
          currentPage={currentPage}
          itemsPerPage={pageSize}
          onChange={onPageSizeHandler}
        />
      </View>
    </View>
  );
};

export default React.memo(SalesTable);
