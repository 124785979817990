import React, { useCallback, useEffect, useState } from 'react';
import {
  Image,
  ImageBackground,
  ImageSourcePropType,
  LayoutRectangle,
  Platform,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  ViewProps,
} from 'react-native';

import PlanNotifyMarker from '../diagrams/PlanNotifyMarker';
import { colorPalette } from '../../styles/Theme';
import StoreNotifications from '../dashboard/StoreNotifications';
import TitleWithBadge from '../elements/TitleWithBadge';
import Shelf from '../dashboard/Shelf';
import { unitPositionMap } from '../../mocks/MockData';
import { StoreService } from '../../services/StoreService';
import { useAppState } from '../../contexts/AuthContext';
import { Unit } from '../../models/Unit';
import useShelveHelpers from '../../hooks/useShelveHelpers';
import useDataUpdateInterval from '../../hooks/useDataUpdateInterval';
import { ProblemTypeEnum } from '../../enums/ProblemType.enum';
import UnitProblemsTable from './UnitProblemsTable';
import PngSwitch from '../elements/Switch';
import { HORIZONTAL_VIEW_WIDTH } from '../../configs/Constants';
import ProblemList from './ProblemList';
import { Avatar } from 'react-native-paper';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colorPalette.basic.white,
    borderRadius: 24,
    padding: 32,
    minHeight: 520,
  },
  planContainer: {
    position: 'relative',
    flex: 1,
    minHeight: 350,
  },
  shelfContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
  },
  cardContainer: {
    width: 250,
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 10,
    paddingBottom: 10,
    marginBottom: 21,
    borderRadius: 8,
    marginRight: 24,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
  },
});

interface ShelveMonitorProps extends ViewProps {
  title?: string;
  plan: ImageSourcePropType;
}

const ShelveMonitor: React.FC<ShelveMonitorProps> = ({ style, title = 'Shelve Monitor', plan }) => {
  const [units, setUnits] = useState<Array<Unit>>([]);
  const [filteredUnits, setFilteredUnits] = useState<Array<Unit>>([]);
  const [selectedUnit, setSelectedUnit] = useState<Unit>();
  const [systemProblemCount, setSystemProblemCount] = useState(0);
  const [lowStockProblemCount, setLowStockProblemCount] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const { token, storeId } = useAppState();
  const { getColorByProblemType, isProblemMultiTypes } = useShelveHelpers();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isShelfView, setIsShelfView] = useState(true);
  const [selectedProblemType, setSelectedProblemType] = useState<ProblemTypeEnum | 'All'>();
  const window = useWindowDimensions();
  const isHorizontalView = window.width > HORIZONTAL_VIEW_WIDTH;

  const dataUpdateHandler = useCallback(() => {
    if (!token || !storeId) return;
    setIsDataLoading(true);
    StoreService.getStoreUnits(storeId)
      .then(units => {
        // Map unit position to units | plan position should be added to DB
        units.forEach(unit => (unit.planPosition = unitPositionMap.get(unit.id)));
        setUnits(units);
      })
      .finally(() => setIsDataLoading(false));
  }, [token, storeId]);

  const filterByProblemType = useCallback(
    (type?: ProblemTypeEnum | 'All'): Array<Unit> => {
      return units.filter(unit => {
        const isProblemsExist = unit.calculateProblemCount() > 0;
        if (!isProblemsExist) return false;
        switch (type) {
          case ProblemTypeEnum.LowStock:
            return unit.getBinLowStockProblemCount() > 0;
          case ProblemTypeEnum.System:
            return unit.getHwProblemCount() > 0;
          default: {
            return isProblemsExist;
          }
        }
      });
    },
    [units],
  );

  useDataUpdateInterval(dataUpdateHandler);

  useEffect(() => {
    dataUpdateHandler();
  }, [dataUpdateHandler]);

  useEffect(() => {
    setIsShelfView(selectedUnit?.primaryProblem !== ProblemTypeEnum.System);
  }, [selectedUnit, setSelectedUnit]);

  useEffect(() => {
    const byProblemType = filterByProblemType(selectedProblemType);
    setFilteredUnits(byProblemType);
  }, [filterByProblemType, selectedProblemType]);

  useEffect(() => {
    setSelectedUnit(filteredUnits[0]);
  }, [filteredUnits]);

  useEffect(() => {
    setSystemProblemCount(units.reduce((accum: number, unit: Unit) => accum + unit.getHwProblemCount(), 0));
    setLowStockProblemCount(units.reduce((accum: number, unit: Unit) => accum + unit.getBinLowStockProblemCount(), 0));
    if (!selectedUnit) setSelectedUnit(units[0]);
  }, [units, selectedUnit]);

  useEffect(() => {
    if (!containerHeight) return;
    const image = Platform.OS === 'web' ? { uri: plan as any } : Image.resolveAssetSource(plan);
    Image.getSize(
      image.uri,
      (width, height) => {
        const horizontalScale = containerWidth / width;
        const verticalScale = containerHeight / height;
        const scale = Math.min(horizontalScale, verticalScale);
        const horizontalOffset = Math.abs((width * scale - containerWidth) / 2);
        const verticalOffset = Math.abs((height * scale - containerHeight) / 2);
        setScale(scale);
        setOffset({ x: horizontalOffset, y: verticalOffset });
      },
      error => console.warn(error),
    );
  }, [plan, containerWidth, containerHeight]);

  const renderedImageSizeHandler = useCallback(
    (layout: LayoutRectangle) => {
      setContainerWidth(layout.width);
      setContainerHeight(layout.height);
    },
    [setContainerWidth, setContainerHeight],
  );

  const onUnitChange = useCallback(
    id => {
      const newUnit = units.find(unit => unit.id === id);
      if (!newUnit) return;
      setSelectedUnit(newUnit);
    },
    [units, setSelectedUnit],
  );

  return (
    <View style={[styles.container, style]}>
      <View>
        <TitleWithBadge
          title={title}
          count={5}
          style={{ maxWidth: 180 }}
          titleStyle={{ fontSize: 18 }}
          isLoading={isDataLoading}
        />
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 32,
            marginTop: 12,
          }}
        >
          <StoreNotifications
            countLowStock={lowStockProblemCount}
            countSystemError={systemProblemCount}
            onChange={setSelectedProblemType}
          />
          {selectedProblemType && selectedProblemType !== 'All' && (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text>Hardware </Text>
              <PngSwitch onChange={setIsShelfView} value={isShelfView} />
              <Text> Shelf</Text>
            </View>
          )}
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Image source={require('../../../assets/images/card-view.png')} style={{height: 18, width: 18, marginRight: 6}} />
            <Image source={require('../../../assets/images/rows-view.png')} style={{height: 14, width: 16, opacity: 0.25}} />
          </View>
        </View>
      </View>
      <View style={{ flexDirection: isHorizontalView ? 'row' : 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {Array(6).fill(0).map(() => {
          return (
            <View style={styles.cardContainer}>
              <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <View style={{backgroundColor: colorPalette.basic.coralRed, paddingVertical: 5, paddingHorizontal: 10, borderRadius: 15, justifyContent: 'center'}}>
                  <Text style={{color: colorPalette.basic.white, fontSize: 12, fontWeight: '700'}}>Low Stock</Text>
                </View>
                <Avatar.Image size={36} source={require('../../../assets/images/avatar.png')} />
              </View>
              <Image source={require('../../../assets/images/drinks/coke.png')} style={{width: 25, height: 48, alignSelf: 'center', marginTop: 9}} />
              <Text style={{marginTop: 11, color: colorPalette.other.link, alignSelf: 'center', fontWeight: '500'}}>Diet Coke</Text>
              <View style={{flexDirection: 'row', marginTop: 8, alignSelf: 'center', alignItems: 'center'}}>
                <Image source={require('../../../assets/images/marker.png')} style={{width: 11, height: 15}} />
                <Text style={{color: colorPalette.basic.lightBlueGray, marginLeft: 5, fontSize: 12, fontWeight: '700'}}>Unit 7/Bin 3</Text>
              </View>
              <View style={{flexDirection: 'row', marginTop: 13, justifyContent: 'space-around'}}>
                <View style={{alignItems: 'center'}}>
                  <Text style={{color: colorPalette.basic.lightBlueGray, fontSize: 12, fontWeight: '700'}}>On Shelve</Text>
                  <Text style={{color: colorPalette.primary, fontSize: 24, fontWeight: '900'}}>4</Text>
                </View>
                <View style={{alignItems: 'center'}}>
                  <Text style={{color: colorPalette.basic.lightBlueGray, fontSize: 12, fontWeight: '700'}}>To Stock</Text>
                  <Text style={{color: colorPalette.primary, fontSize: 24, fontWeight: '900'}}>10</Text>
                </View>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default React.memo(ShelveMonitor);
