import { useEffect } from 'react';
import { DATA_REFRESH_INTERVAL } from '../configs/Constants';

const useDataUpdateInterval = (fn: Function, refreshInterval = DATA_REFRESH_INTERVAL) => {
  useEffect(() => {
    fn();
    const interval = setInterval(() => {
      fn();
    }, refreshInterval);
    return () => clearInterval(interval);
  }, [fn, refreshInterval]);
};

export default useDataUpdateInterval;
