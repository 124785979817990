import React from 'react';
import { Text, View } from 'react-native';

import { screenStyles } from '../styles/Shared';

const StorySetupScreen: React.FC = () => {
  return (
    <View style={screenStyles.container}>
      <Text>Store Setup</Text>
    </View>
  );
};

export default StorySetupScreen;
