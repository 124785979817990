import React, { useEffect, useState } from 'react';
import { DeviceEventEmitter } from 'react-native';

import apiClient from '../utils/ApiClient';
import { AuthClient } from '../utils/AuthClient';
import { AUTH_PASSWORD, AUTH_USER } from '../configs/Constants';

type ContextProps = {
  token: string;
  storeId: string;
  setStoreId: (storeId: string) => void;
};

const AppContext = React.createContext<Partial<ContextProps>>({});

export function useAppState() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }

  return context;
}

export const AppProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState('');
  const [storeId, setStoreId] = useState('5e72ec2a21be024732b7c58b');

  useEffect(() => {
    const authInit = async () => {
      const authClient = AuthClient.getInstance(AUTH_USER, AUTH_PASSWORD);
      const token = await authClient.getToken();
      setToken(token);
    };
    const listener = () => {
      authInit();
    };
    DeviceEventEmitter.addListener('tokenExpired', listener);
    authInit();

    return () => DeviceEventEmitter.removeListener('tokenExpired', listener);
  }, []);

  useEffect(() => {
    apiClient.defaults.headers.common['x-auth-token'] = token;
  }, [token]);

  return <AppContext.Provider value={{ token, storeId, setStoreId }}>{children}</AppContext.Provider>;
};
