import React from 'react';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import { Avatar, Button, Divider } from 'react-native-paper';
import TitleWithBadge from '../elements/TitleWithBadge';
import { latoFontStyle } from '../../styles/Shared';
import { colorPalette } from '../../styles/Theme';
import moment from 'moment';

export const EmployeesInStore = () => {
  const styles = StyleSheet.create({
    container: {
      position: 'relative',
      flexDirection: 'column',
      backgroundColor: colorPalette.basic.white,
      paddingLeft: 20,
      paddingTop: 21,
      paddingBottom: 7,
      borderRadius: 24,
    },
    viewAllLabel: {
      fontSize: 12,
      color: colorPalette.basic.gunPowder,
    },
  });

  return (
    <View style={[styles.container]}>
      <TitleWithBadge title="Employees in Store" count={3} isLoading={false} />
      <View style={{flexDirection: 'row', paddingRight: 20, marginBottom: 10, alignItems: 'center'}}>
        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
          {/* @ts-ignore */}
          <Avatar.Icon size={38} icon="file-image" />
          <Text style={{marginLeft: 7, fontSize: 12}}>{`Employee${'\n'}Name`}</Text>
        </View>
        <Text>{moment().format('h:mm A')}</Text>
      </View>
      <View style={{flexDirection: 'row', paddingRight: 20, marginBottom: 10, alignItems: 'center'}}>
        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
          {/* @ts-ignore */}
          <Avatar.Icon size={38} icon="file-image" />
          <Text style={{marginLeft: 7, fontSize: 12}}>{`Employee${'\n'}Name`}</Text>
        </View>
        <Text>{moment().format('h:mm A')}</Text>
      </View>
      <View style={{flexDirection: 'row', paddingRight: 20, marginBottom: 10, alignItems: 'center'}}>
        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
          {/* @ts-ignore */}
          <Avatar.Icon size={38} icon="file-image" />
          <Text style={{marginLeft: 7, fontSize: 12}}>{`Employee${'\n'}Name`}</Text>
        </View>
        <Text>{moment().format('h:mm A')}</Text>
      </View>
      {/* @ts-ignore */}
      <Button
        disabled={true}
        uppercase={false}
        contentStyle={{ justifyContent: 'flex-end' }}
        labelStyle={[latoFontStyle.regular, styles.viewAllLabel]}
        onPress={() => {}}
      >
        View all
      </Button>
    </View>
  );
};