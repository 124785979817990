import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { Orientation } from 'expo-screen-orientation/src/ScreenOrientation.types';
import * as ScreenOrientation from 'expo-screen-orientation';

import { screenStyles } from '../styles/Shared';

const SystemScreen: React.FC = () => {
  const [orientation, setOrientation] = useState<Orientation>();

  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(data => {
      setOrientation(data.orientationInfo.orientation);
    });

    return () => {
      ScreenOrientation.removeOrientationChangeListeners();
    };
  }, []);

  ScreenOrientation.getOrientationAsync().then(data => {
    setOrientation(data);
  });

  return (
    <View style={screenStyles.container}>
      <Text>System</Text>

      <Text>Orientation: {orientation}</Text>
    </View>
  );
};

export default SystemScreen;
