import { Expose } from 'class-transformer';

export class Hardware {
  @Expose({ name: 'hwId' })
  public id: string;

  @Expose()
  public bin: number;

  @Expose()
  public name: string;

  @Expose()
  public status: string;

  @Expose()
  public hwType: string;

  constructor(id: string, bin: number, name: string, status: string, hwType: string) {
    this.id = id;
    this.bin = bin;
    this.name = name;
    this.status = status;
    this.hwType = hwType;
  }
}
