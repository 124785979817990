import React, { useCallback, useEffect, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { IconButton } from 'react-native-paper';
import { colorPalette } from '../../styles/Theme';
import RNPickerSelect from 'react-native-picker-select';

const styles = StyleSheet.create({
  text: {
    color: colorPalette.basic.santasGray,
  },
  pageSize: {
    height: 20,
    width: 42,
    marginRight: 12,
    borderWidth: 0,
  },
});

interface PaginationProps {
  itemsPerPage?: number;
  totalItemCount: number;
  currentPage: number;
  onChange: (page: number, size: number) => void;
}

const firstPage = 1;

const Pagination: React.FC<PaginationProps> = ({ totalItemCount, itemsPerPage = 10, currentPage, onChange }) => {
  const [pageSize, setPageSize] = useState(itemsPerPage);
  const [page, setPage] = useState(currentPage);
  const [maxPageIndex, setMaxPageIndex] = useState(Math.ceil(totalItemCount / pageSize));

  useEffect(() => {
    setMaxPageIndex(Math.ceil(totalItemCount / pageSize));
  }, [totalItemCount, pageSize]);

  const pageSizeChangeHandler = useCallback(
    value => {
      setPageSize(value);
      setPage(firstPage);
      onChange(page, pageSize);
    },
    [page, pageSize, onChange],
  );

  const currentPageChangeHandler = (value: number) => {
    const newPageIndex = page + value;
    if (newPageIndex > 0 && newPageIndex <= maxPageIndex) {
      setPage(newPageIndex);
      onChange(newPageIndex, pageSize);
    }
  };

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Rows per page: </Text>
        <RNPickerSelect
          style={{
            viewContainer: {
              width: 42,
              height: 18,
              marginHorizontal: 8,
              borderWidth: 0,
              alignSelf: 'center',
            },
            inputWeb: {
              width: 42,
              height: 18,
              borderWidth: 0,
              alignSelf: 'center',
            },
            inputAndroid: { width: 42, height: 18, alignSelf: 'center' },
            inputIOSContainer: { width: 42, height: 18, alignSelf: 'center' },
          }}
          value={pageSize}
          items={[
            { label: '10', value: 10 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
          ]}
          onValueChange={itemValue => pageSizeChangeHandler(parseInt(itemValue))}
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Text style={styles.text}>
          {page * pageSize - pageSize || firstPage}-{Math.min(page * pageSize, totalItemCount)} of {totalItemCount}
        </Text>
        {/* @ts-ignore */}
        <IconButton
          size={24}
          disabled={page === firstPage}
          color={colorPalette.basic.black}
          icon="chevron-left"
          onPress={() => currentPageChangeHandler(-1)}
        />
        {/* @ts-ignore */}
        <IconButton
          size={24}
          disabled={page === maxPageIndex}
          color={colorPalette.basic.black}
          icon="chevron-right"
          onPress={() => currentPageChangeHandler(1)}
        />
      </View>
    </View>
  );
};

export default Pagination;
