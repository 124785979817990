import React, { useCallback, useState } from 'react';
import { LineChart } from 'react-native-chart-kit';
import { LineChartData } from 'react-native-chart-kit/dist/line-chart/LineChart';
import { Platform, View } from 'react-native';
import Svg, { G, Path, Text } from 'react-native-svg';
import { abbreviateNumber } from 'js-abbreviation-number';

import { colorPalette } from '../styles/Theme';

interface SalesChartProps {
  data: LineChartData;
  width: number;
  height?: number;
}

// eslint-disable-next-line max-len
const originShape = `M64.4876 70.6591C63.2743 72.447 61.3071 72.447 60.0938 70.6591L58.2739 68.1085C57.3976 66.8804 56.9595 66.2664 56.3947 65.8228C55.8945 65.43 55.324 65.1362 54.7138 64.9572C54.0246 64.7551 53.2703 64.7551 51.7617 64.7551H13.2038C8.96027 64.7551 7.42147 64.2507 5.8701 63.3036C4.31873 62.3565 3.1012 60.9666 2.27152 59.1956C1.44184 57.4246 1 55.668 1 50.8238V14.9313C1 10.0871 1.44184 8.33046 2.27152 6.55949C3.1012 4.78851 4.31873 3.39864 5.8701 2.45151C7.42147 1.50438 8.96027 1 13.2038 1H108.796C113.04 1 114.579 1.50438 116.13 2.45151C117.681 3.39864 118.899 4.78851 119.728 6.55949C120.558 8.33046 121 10.0871 121 14.9313V50.8238C121 55.668 120.558 57.4246 119.728 59.1956C118.899 60.9666 117.681 62.3565 116.13 63.3036C114.579 64.2507 113.04 64.7551 108.796 64.7551H75.9531C73.9012 64.7551 72.8753 64.7551 71.9193 64.9432C70.0672 65.3076 68.356 66.1887 66.9836 67.4847C66.2753 68.1537 65.6794 68.9888 64.4876 70.6591V70.6591Z`;
// eslint-disable-next-line max-len
const rotatedShape = `M64.4876 2.34089C63.2743 0.553033 61.3071 0.553033 60.0938 2.34089L58.2739 4.89153C57.3976 6.11961 56.9595 6.73364 56.3947 7.17721C55.8945 7.57 55.324 7.86376 54.7138 8.04276C54.0246 8.24489 53.2703 8.24489 51.7617 8.24489H13.2038C8.96027 8.24489 7.42147 8.74928 5.8701 9.69641C4.31873 10.6435 3.1012 12.0334 2.27152 13.8044C1.44184 15.5754 1 17.332 1 22.1762V58.0687C1 62.9129 1.44184 64.6695 2.27152 66.4405C3.1012 68.2115 4.31873 69.6014 5.8701 70.5485C7.42147 71.4956 8.96027 72 13.2038 72H108.796C113.04 72 114.579 71.4956 116.13 70.5485C117.681 69.6014 118.899 68.2115 119.728 66.4405C120.558 64.6695 121 62.9129 121 58.0687V22.1762C121 17.332 120.558 15.5754 119.728 13.8044C118.899 12.0334 117.681 10.6435 116.13 9.69641C114.579 8.74928 113.04 8.24489 108.796 8.24489H75.9531C73.9012 8.24489 72.8753 8.24489 71.9193 8.05682C70.0672 7.69245 68.356 6.81131 66.9836 5.51528C66.2753 4.84631 65.6794 4.01117 64.4876 2.34089V2.34089Z`;
const shapeSize = { width: 120, height: 71 };

const SalesChart: React.FC<SalesChartProps> = ({ data, width, height = 250 }) => {
  const [tooltipPos, setTooltipPos] = useState({
    x: 0,
    y: 0,
    visible: false,
    value: 0,
  });

  const currencyFormatter = useCallback((price: number) => {
    return `$${new Intl.NumberFormat('en-IN').format(price)}`;
  }, []);

  return (
    <View>
      <LineChart
        data={data}
        width={width}
        height={height}
        withVerticalLines={false}
        withHorizontalLines={true}
        withDots={true}
        transparent={true}
        bezier={true}
        formatYLabel={value => abbreviateNumber(parseInt(value), 0)}
        chartConfig={{
          color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
          backgroundColor: '#f2f2f2',
          backgroundGradientFrom: '#ffffff',
          backgroundGradientTo: '#ffffff',
          decimalPlaces: 2,
          labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
          propsForDots: {
            r: '6',
          },
        }}
        decorator={() => {
          return tooltipPos.visible ? (
            <G
              x={tooltipPos.x - shapeSize.width / 2}
              y={tooltipPos.y > shapeSize.height ? tooltipPos.y - shapeSize.height : tooltipPos.y}
            >
              <Svg width={shapeSize.width} height={shapeSize.height} viewBox="0 0 124 72">
                <Path
                  d={tooltipPos.y > shapeSize.height ? originShape : rotatedShape}
                  fill="#ffffff"
                  stroke={colorPalette.basic.boulder}
                />
                <Text
                  x={62}
                  y={tooltipPos.y > shapeSize.height ? 35 : 45}
                  fill="#000000"
                  fontSize={Platform.OS === 'ios' ? 16 : 20}
                  fontWeight="bold"
                  textAnchor="middle"
                >
                  {currencyFormatter(tooltipPos.value)}
                </Text>
              </Svg>
            </G>
          ) : null;
        }}
        onDataPointClick={data => {
          const isSamePoint = tooltipPos.x === data.x && tooltipPos.y === data.y;

          isSamePoint
            ? setTooltipPos(previousState => {
                return {
                  ...previousState,
                  value: data.value,
                  visible: !previousState.visible,
                };
              })
            : setTooltipPos({
                x: data.x,
                value: data.value,
                y: data.y,
                visible: true,
              });
        }}
      />
    </View>
  );
};

export default SalesChart;
