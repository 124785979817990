import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Menu, Avatar, Badge } from 'react-native-paper';
import { AvatarImageSource } from 'react-native-paper/lib/typescript/components/Avatar/AvatarImage';

import { colorPalette } from '../styles/Theme';

const styles = StyleSheet.create({
  buttonLabel: {
    fontFamily: 'Lato_900Black',
    textTransform: 'none',
  },
  activeBadge: {
    position: 'absolute',
    bottom: 2,
    right: 2,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: colorPalette.basic.white,
    backgroundColor: colorPalette.basic.green,
  },
});

interface ProfileMenuProps {
  name: string;
  image: AvatarImageSource;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ name, image }) => {
  const [visible, setVisible] = React.useState(false);

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <View style={{ position: 'relative' }}>
        <Avatar.Image size={50} source={image} />
        <Badge style={styles.activeBadge} size={12} />
      </View>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <Button uppercase={false} labelStyle={styles.buttonLabel} color={colorPalette.primary} onPress={openMenu}>
            {name}&nbsp;&nbsp;▾
          </Button>
        }
      >
        <Menu.Item disabled onPress={() => closeMenu} title="Profile" />
        <Menu.Item disabled onPress={() => closeMenu} title="Link 2" />
        <Menu.Item disabled onPress={() => closeMenu} title="Logout" />
      </Menu>
    </View>
  );
};

export default React.memo(ProfileMenu);
