import React, { useCallback, useState } from 'react';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import { colorPalette } from '../../styles/Theme';
import { TableCellTypeEnum as TCTE } from '../../enums/TableCellType.enum';
import { latoFontStyle } from '../../styles/Shared';
import { ICellSize } from '../../interfaces/ICellSize';
import ProductImage from '../dashboard/ProductImage';
import { SortStateEnum } from '../../enums/SortState.enum';
import { IconButton } from 'react-native-paper';
import { BIN_LOW_STOCK_COUNT } from '../../configs/Constants';
import TableFilterMenu from './TableFilterMenu';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: colorPalette.basic.athensGray,
    borderBottomWidth: 1,
  },
  body: {
    borderColor: colorPalette.basic.gray,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: colorPalette.basic.athensGray,
    borderBottomWidth: 1,
  },
  cell: {
    flex: 1,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
  },
  headerLabel: {
    fontSize: 14,
    fontWeight: '700',
    color: colorPalette.basic.santasGray,
  },
  pagination: {},
});

interface ProductsTableProps {
  labels: Array<string>;
  customSizeMap?: Map<number, ICellSize>;
  filterConfigMap?: Map<number, FilterConfig>;
  customRenderMap?: Map<TCTE, () => void>;
  cellTypes: Array<TCTE>;
  dataset: Array<Array<any>>;
  onSortChange: (index?: number, state?: SortStateEnum) => void;
}

export interface FilterConfig {
  availableValues: Array<string | number>;
  changeHandler: (value: string | number) => void;
}

const Table: React.FC<ProductsTableProps> = ({
  labels,
  customSizeMap,
  filterConfigMap,
  cellTypes,
  dataset,
  onSortChange,
}) => {
  const [sortState, setSortState] = useState<{ index: number; state: SortStateEnum } | undefined>();
  const onSortStateChange = useCallback(
    (index: number) => {
      let newState = undefined;
      if (!sortState || sortState.index !== index) {
        newState = { index: index, state: SortStateEnum.ASC };
      } else if (sortState.state === SortStateEnum.ASC) {
        newState = { index: index, state: SortStateEnum.DESC };
      }
      setSortState(newState);
      onSortChange(newState?.index, newState?.state);
    },
    [sortState, onSortChange],
  );

  const currencyFormatter = useCallback((price: number) => {
    return `$${new Intl.NumberFormat('en-IN').format(price)}`;
  }, []);

  const cellRenderHandler = (cellType: TCTE, value: any) => {
    if (cellType === TCTE.Text) {
      return <Text>{value}</Text>;
    } else if (cellType === TCTE.Number) {
      return <Text>{value}</Text>;
    } else if (cellType === TCTE.LowStock) {
      return value <= BIN_LOW_STOCK_COUNT ? (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* @ts-ignore */}
          <IconButton size={16} icon="alert-circle" color={colorPalette.basic.coralRed} />
          <Text>{value}</Text>
        </View>
      ) : (
        <Text>{value}</Text>
      );
    } else if (cellType === TCTE.Image) {
      return <Image style={{ width: 48, height: 48 }} source={value} width={48} height={48} resizeMode="contain" />;
    } else if (cellType === TCTE.ProductImage) {
      return <ProductImage style={{ width: 48, height: 48 }} height={48} resizeMode="contain" productId={value} />;
    } else if (cellType === TCTE.Currency) {
      return <Text>{currencyFormatter(value)}</Text>;
    } else if (cellType === TCTE.PaymentImage) {
      return <Image style={{width: 16, height: 16}} source={value} />
    }
    return <Text>{value}</Text>;
  };

  const getCustomSizeConfig = useCallback(
    (cellIndex: number) => {
      if (!customSizeMap || !customSizeMap.has(cellIndex)) return {};
      const conf = customSizeMap.get(cellIndex)!;
      return conf.fixed ? { flex: 0, flexBasis: conf.fixed } : { flex: conf.responsive || 1 };
    },
    [customSizeMap],
  );

  const getSortStateIcon = useCallback(
    (index: number) => {
      if (!sortState || sortState.index !== index) {
        return 'minus';
      }
      return sortState.state === SortStateEnum.ASC ? 'chevron-up' : 'chevron-down';
    },
    [sortState],
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {labels.map((label, index) => (
          <View key={label} style={[styles.cell, getCustomSizeConfig(index)]}>
            <Text style={[latoFontStyle.bold, styles.headerLabel]}>{label}</Text>
            {cellTypes[index] !== TCTE.ProductImage && cellTypes[index] !== TCTE.Image && (
              // @ts-ignore
              <IconButton
                size={18}
                color={colorPalette.basic.black}
                icon={getSortStateIcon(index)}
                onPress={() => onSortStateChange(index)}
              />
            )}
            {filterConfigMap && filterConfigMap?.has(index) && Platform.OS !== 'android' && (
              <TableFilterMenu
                values={filterConfigMap.get(index)?.availableValues}
                changeHandler={filterConfigMap.get(index)?.changeHandler}
              />
            )}
          </View>
        ))}
      </View>
      <View style={styles.body}>
        {dataset.map((row, i) => (
          <View key={`row-${i}`} style={styles.row}>
            {row
              .filter((_, k) => cellTypes[k] !== TCTE.Hidden)
              .map((value, k) => (
                <View key={`row-${i}-cell-${k}`} style={[styles.cell, getCustomSizeConfig(k)]}>
                  {cellRenderHandler(cellTypes[k], value)}
                </View>
              ))}
          </View>
        ))}
      </View>
    </View>
  );
};

export default Table;
